import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';
import { Table } from '@tanstack/react-table';
import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  onPageChange: (newPageIndex: number) => void;
  onPageSizeChange: (newPageSize: number) => void; // Add prop to handle page size change
}

export function DataTablePagination<TData>({
  table,
  onPageChange,
  onPageSizeChange,
}: DataTablePaginationProps<TData>) {
  return (
    <div className='flex items-center justify-between w-2/3 '>
      <div className=' flex items-center justify-between w-full  space-x-6 lg:space-x-8'>
        <div className='flex items-center space-x-6 lg:space-x-4 '>
          <div className='flex items-center space-x-2'>
            <Button
              variant='ghost'
              className='h-8 w-8 p-0'
              onClick={() => {
                table.previousPage(); // Moves to previous page
                onPageChange(table.getState().pagination.pageIndex - 1); // Reflect the correct page index after change
              }}
              disabled={!table.getCanPreviousPage()}
            >
              <span className='sr-only'>Go to previous page</span>
              <ChevronLeftIcon className='h-6 w-6' />
            </Button>
            <div className='flex w-[100px] items-center justify-center text-sm text-[#979797] font-medium'>
              Page {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </div>
            <Button
              variant='ghost'
              className='h-8 w-8 p-0'
              onClick={() => {
                table.nextPage(); // Moves to next page
                onPageChange(table.getState().pagination.pageIndex + 1); // Reflect the correct page index after change
              }}
              disabled={!table.getCanNextPage()}
            >
              <span className='sr-only'>Go to next page</span>
              <ChevronRightIcon className='h-6 w-6' />
            </Button>
          </div>
        </div>
        {/* <div className='flex items-center space-x-2'>
          <p className='text-sm font-medium text-[#979797]'>Rows per page</p>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value) => {
              onPageSizeChange(Number(value)); // Trigger the parent function
            }}
          >
            <SelectTrigger className='text-[#979797] border-none bg-transparent h-8 w-[70px]'>
              <SelectValue placeholder={table.getState().pagination.pageSize} />
            </SelectTrigger>
            <SelectContent side='top'>
              {[2, 7, 10, 20, 30, 40, 50].map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div> */}
      </div>
    </div>
  );
}
