import { getTeacher } from '@/api/teacher';
import { useState, useEffect } from 'react';

const useTeacherById = (id: string) => {
  const [teacher, setTeacher] = useState<Client | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTeacher = async () => {
      try {
        const response = await getTeacher(id);

        setTeacher(response);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching Teacher:', error);
        setLoading(false);
      }
    };

    fetchTeacher();
  }, [id]);

  return { ...teacher, loading };
};

export default useTeacherById;
