import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { Textarea } from '@/components/ui/textarea';

type Props = {};

const Delete = (props: Props) => {
  return (
    <Dialog>
      <DialogTrigger className='bg-[#FF6636] rounded-[15px] text-sm text-white px-4 py-3'>
        Delete
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            Delete Consultant
            <p className='text-sm text-[#707070] font-normal my-4'>
              You are going to Delete this Consultant
            </p>
          </DialogTitle>
          <div className='border-t py-4'>
            <p>Are you sure to Delete the Consultant?</p>

            <div className='space-y-2 mt-4'>
              <Label className='mt-4 font-normal'>Detail</Label>
              <Textarea rows={5} placeholder='Details' />
            </div>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default Delete;
