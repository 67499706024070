// Function to get the token from cookies

// Function to decode the JWT token and extract the expiry
const decodeJWT = (token: string) => {
  try {
    const base64Url = token.split('.')[1]; // Get the payload part of the JWT
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );

    return JSON.parse(jsonPayload); // Parse the payload into an object
  } catch (error) {
    console.error('Invalid token format', error);
    return null;
  }
};

export const saveAuthData = (userData: any) => {
  const response = userData;
  const decodedToken = decodeJWT(response.accessToken);

  if (!decodedToken) {
    console.error('Failed to decode token');
    return;
  }

  // Expiry time in seconds (JWT stores it as a Unix timestamp)
  const expiry = decodedToken.exp * 1000; // Convert to milliseconds

  const { accessToken, ...userWithoutToken } = response;

  const authData = JSON.stringify({
    user: userWithoutToken,
    expiry,
  });

  document.cookie = `access_token=${response.accessToken}; expires=${expiry}; path=/`;

  localStorage.setItem('authData', authData);
};
const getTokenFromCookies = (): string | null => {
  const name = 'access_token=';
  return decodeURIComponent(document.cookie).split('=')[1];
  // const cookies = decodedCookie.split(';');
  // for (let i = 0; i < cookies.length; i++) {
  //   let cookie = cookies[i];
  //   while (cookie.charAt(0) === ' ') {
  //     cookie = cookie.substring(1);
  //   }
  //   if (cookie.indexOf(name) === 0) {
  //     return cookie.substring(name.length, cookie.length);
  //   }
  // }

  // return null;
};

// Function to check if the token is still valid
const isTokenValid = (token: string): boolean => {
  const decodedToken = decodeJWT(token);
  if (!decodedToken || !decodedToken.exp) {
    return false; // Invalid or no expiration
  }
  const currentTime = Date.now() / 1000; // Convert to seconds
  return decodedToken.exp > currentTime; // Check if token has not expired
};

// Unified function to get token, check validity, and return response
export const getToken = (): { token: string | null; valid: boolean } => {
  const token = getTokenFromCookies();

  const valid = token ? isTokenValid(token) : false;

  return { token, valid };
};

export const getUser = (): any => {
  try {
    const { user } = JSON.parse(localStorage.getItem('authData') || '{}');
    if (!user) {
      console.error('User not found in local storage');
      return null;
    }
    return user;
  } catch (error) {
    console.error('Failed to get user from local storage', error);
    return null;
  }
};

export const getUserRole = (): string | null => {
  try {
    const { user } = JSON.parse(localStorage.getItem('authData') || '{}');
    if (!user) {
      console.error('User not found in local storage');
      return null;
    }
    if (!user.role) {
      console.error('Role not found in user object');
      return null;
    }
    return user.role;
  } catch (error) {
    console.error('Failed to get user from local storage', error);
    return null;
  }
};
