import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import useCommunes from '@/hooks/useCommunes';

interface CustomSelectProps {
  wilaya: string;
  placeholder: string;
  onValueChange: (value: string) => void;
  defaultValue: string;
}

const CommuneDropdown = ({
  wilaya,
  placeholder,
  onValueChange,
  defaultValue,
}: CustomSelectProps) => {
  const { communesList, loading } = useCommunes(wilaya);

  return (
    <Select
      disabled={loading}
      onValueChange={onValueChange}
      defaultValue={wilaya}
    >
      <SelectTrigger className='h-[44px] font-normal flex justify-between w-full rounded-[5px] border border-[#C9C9C9] bg-white px-3 py-1 text-sm shadow-sm transition-colors placeholder:text-muted-foreground/50   disabled:cursor-not-allowed disabled:opacity-50 '>
        {defaultValue ? (
          defaultValue
        ) : (
          <span className='text-[#C9C9C9]'>Commune</span>
        )}
      </SelectTrigger>
      <SelectContent>
        {communesList === undefined ? (
          <SelectItem value='Commune'>Choissiez une wilaya</SelectItem>
        ) : (
          <>
            {communesList?.communes.map((item: string) => (
              <SelectItem key={item} value={item}>
                {item}
              </SelectItem>
            ))}
          </>
        )}
      </SelectContent>
    </Select>
  );
};

export default CommuneDropdown;
