import React from 'react';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';

export default function StepAddInformation({ formValues }: any) {
  return (
    <div className=' px-28 flex flex-col justify-start items-center   '>
      <h1 className='font-semibold text-[40px] '>Student's information</h1>
      <p>Fill in the student’s informations</p>
      <div className='w-full space-y-4 mt-8'>
        <Form {...formValues}>
          <FormField
            control={formValues.control}
            name='childName'
            render={({ field }) => (
              <FormItem className=''>
                <FormLabel>
                  <span className='text-sm font-medium'>Child's name</span>
                </FormLabel>
                <FormControl>
                  <Input placeholder='Full name' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={formValues.control}
            name='childAge'
            render={({ field }) => (
              <FormItem className=''>
                <FormLabel>
                  <span className='text-sm font-medium'>Age</span>
                </FormLabel>
                <FormControl>
                  <Input placeholder='Email' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
      </div>
    </div>
  );
}
