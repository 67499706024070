import { Route, Routes } from 'react-router-dom';
import {
  AddClient,
  BecomeATeacher,
  Clients,
  Login,
  PageNotFound,
  Teacher,
  Student,
  StudentDetail,
  TeacherDetail,
  Course,
  CourseDetail,
  Consultant,
  ConsultantDetail,
  StudentPlatform,
  StudentLogin,
  StudentSignup,
} from './pages';
import { Toaster } from 'react-hot-toast';
import ProtectedRoute from './pages/auth/ProtectedRoute';

const App = () => {
  return (
    <>
      <Toaster />
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/student/platform' element={<StudentPlatform />} />
        <Route path='/student/platform/login' element={<StudentLogin />} />
        <Route path='/student/platform/signup' element={<StudentSignup />} />

        <Route
          path='/'
          element={
            <ProtectedRoute>
              <Teacher />
            </ProtectedRoute>
          }
        />
        <Route
          path='/clients'
          element={
            <ProtectedRoute>
              <Clients />
            </ProtectedRoute>
          }
        />

        <Route
          path='/client/add'
          element={
            <ProtectedRoute>
              <AddClient />
            </ProtectedRoute>
          }
        />

        <Route path='/becomeateacher' element={<BecomeATeacher />} />

        <Route path='*' element={<PageNotFound />} />
        <Route
          path='/teachers'
          element={
            <ProtectedRoute>
              <Teacher />
            </ProtectedRoute>
          }
        />
        <Route
          path='/teacher/:id'
          element={
            <ProtectedRoute>
              <TeacherDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path='/students'
          element={
            <ProtectedRoute>
              <Student />
            </ProtectedRoute>
          }
        />

        <Route
          path='/student/:id'
          element={
            <ProtectedRoute>
              <StudentDetail />
            </ProtectedRoute>
          }
        />

        <Route
          path='/classes'
          element={
            <ProtectedRoute>
              <Course />
            </ProtectedRoute>
          }
        />

        <Route
          path='/class/:id'
          element={
            <ProtectedRoute>
              <CourseDetail />
            </ProtectedRoute>
          }
        />
        <Route
          path='/consultants'
          element={
            <ProtectedRoute>
              <Consultant />
            </ProtectedRoute>
          }
        />

        <Route
          path='/consultant/:id'
          element={
            <ProtectedRoute>
              <ConsultantDetail />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
};

export default App;
