import api from './baseApi';

export const addConsultant = async (consultant: Consultant) => {
  try {
    const response = await api.post('/consultant', consultant);
    return response;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

export const getConsultants = async ({
  page = 1,
  perPage = 10,
  searchTerm,
}: {
  page?: number;
  perPage?: number;
  searchTerm?: string;
}) => {
  try {
    let query = `?page=${page}&perPage=${perPage}`;
    if (searchTerm) {
      query += `&fullname=${searchTerm}`;
    }

    const response = await api.get(`/users/list/client${query}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching consultants', error);
    return [];
  }
};

export const deleteConsultant = async (consultantId: string) => {
  try {
    const response = await api.delete(`/consultant/${consultantId}`);
    return response;
  } catch (error) {
    console.error('Error deleting consultant', error);
  }
};
