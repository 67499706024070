import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateTeacherInfo } from '@/redux/slices/becomeATeacherSlice';
import { RootState } from '@/redux/store'; // Adjust based on your store setup
import { PlusIcon } from 'lucide-react';
import DatePicker from './DatePicker';
import { Textarea } from './ui/textarea';

const AddDiploma = () => {
  const dispatch = useDispatch();
  const diplomas = useSelector(
    (state: RootState) => state.becomeATeacher.teacherInfo.diplomas
  );

  const [title, setTitle] = useState('');
  const [company, setCompany] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [details, setDetails] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleAddExperience = () => {
    setSubmitted(true);

    if (!title || !company || !startDate || !endDate || !details) {
      return; // Prevent submission if fields are incomplete
    }

    const newExperience = {
      title,
      company,
      startDate,
      endDate,
      details,
    };

    dispatch(
      updateTeacherInfo({
        diplomas: [...diplomas, newExperience],
      })
    );

    // Reset fields after dispatch
    setTitle('');
    setCompany('');
    setStartDate('');
    setEndDate('');
    setDetails('');
    setSubmitted(false); // Reset validation state after submission
  };

  return (
    <Dialog>
      <DialogTrigger className='bg-[#F6F6F6] rounded-full p-2'>
        <PlusIcon size={20} className='cursor-pointer' />
      </DialogTrigger>
      <DialogContent className='p-12'>
        <DialogHeader>
          <DialogTitle>Add Academic Diploma</DialogTitle>
          <p className='text-[#707070] text-sm'>
            Please fill in the details of your Academic Diploma
          </p>
        </DialogHeader>

        <div className='space-y-4'>
          <div className='bg-[#EEEEEE] w-full h-[1px]' />
          <img src='/assets/icons/line.svg' alt='line' />
          <div className='w-1/2 space-y-2'>
            <Label className='text-[#1D2026]'>Academic Diploma Title</Label>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder='Title'
            />
            {submitted && !title && (
              <p className='text-red-500 text-sm'>
                Academic Diploma Title is required
              </p>
            )}
          </div>
          <div className='w-1/2 space-y-2'>
            <Label className='text-[#1D2026]'>Institute</Label>
            <Input
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              placeholder='Company'
            />
            {submitted && !company && (
              <p className='text-red-500 text-sm'>Institute is required</p>
            )}
          </div>
          <div className='flex pt-2 gap-4 w-full'>
            <div className='w-1/2 flex flex-col gap-2'>
              <Label className='text-[#1D2026]'>Start Date</Label>
              <DatePicker
                date={startDate ? new Date(startDate) : undefined}
                onPickDate={(e) => e && setStartDate(e?.toISOString())}
              />
              {submitted && !startDate && (
                <p className='text-red-500 text-sm'>Start Date is required</p>
              )}
            </div>
            <div className='w-1/2 flex flex-col gap-2'>
              <Label className='text-[#1D2026]'>End Date</Label>
              <DatePicker
                date={endDate ? new Date(endDate) : undefined}
                onPickDate={(e) => e && setEndDate(e?.toISOString())}
              />
              {submitted && !endDate && (
                <p className='text-red-500 text-sm'>End Date is required</p>
              )}
            </div>
          </div>

          <div className='space-y-2'>
            <Label className='text-[#1D2026]'>Details</Label>
            <Textarea
              rows={5}
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              placeholder='Details'
            />
            {submitted && !details && (
              <p className='text-red-500 text-sm'>Details are required</p>
            )}
          </div>
        </div>

        <div className='mt-4 ml-auto'>
          <DialogClose>
            <Button variant={'ghost'} onClick={handleAddExperience}>
              Cancel
            </Button>
          </DialogClose>
          <Button onClick={handleAddExperience}>Confirm</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddDiploma;
