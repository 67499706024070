import { useForm } from 'react-hook-form';
import { useState } from 'react';
import axios from 'axios';

interface StudentPlatform {
  fullname: string;
  email: string;
  phone: string;
  password: string;
  signedContract: boolean;
  childName: string;
  childAge: string;
}

export const useSignup = () => {
  const [submitting, setSubmitting] = useState(false);

  const signupFormValues = useForm({
    defaultValues: {
      fullname: '',
      email: '',
      phone: '',
      password: '',
      signedContract: false,
      childName: '',
      childAge: '',
    },
  });

  const onSubmit = async (values: StudentPlatform) => {
    console.log(values);
    setSubmitting(true);
    try {
      const response = await axios.post('/auth/signup/student', values);
      console.log(response);
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return { signupFormValues, submitting, onSubmit };
};
