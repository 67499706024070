import { Checkbox } from '@/components/ui/checkbox';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateContract } from '@/redux/slices/becomeATeacherSlice';
import { Label } from '@/components/ui/label';

type Props = {
  currentStep: number;
  handleNext: () => void;
  handlePrevious: () => void;
  steps: Array<{ label: string; desc: string }>;
};

const Contract = ({
  currentStep,
  handleNext,
  handlePrevious,
  steps,
}: Props) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);

  const handleCheckedChange = () => {
    const newCheckedValue = !checked;
    setChecked(newCheckedValue);
    dispatch(updateContract({ checked: newCheckedValue })); // Update Redux state
  };

  return (
    <div>
      <div className='flex divide-x-2 divide-[#EEEEEE]'>
        <div className='w-1/3 divide-y-2 divide-[#EEEEEE]'>
          <p className='py-4'>We would like to sign a contract with you!</p>
          <div className='py-4'>
            {' '}
            <div className='flex gap-3 items-start'>
              <img src='assets/icons/Info.svg' alt='Info' />
              <p>
                By signing up, you acknowledge that this contract is legally
                binding and irreversible
              </p>
            </div>
          </div>
        </div>
        <div className='w-2/3 divide-y-2 divide-[#EEEEEE]'>
          <div className='p-2.5  px-10'>
            <p className='text-[24px] font-semibold'>Contract Details</p>
          </div>
          <div className='p-4 h-[500px] space-y-8  px-10 overflow-scroll'>
            <div className='space-y-4'>
              <h2 className='font-bold text-[24px]'>Section 1</h2>
              <p className='text-[16px] font-normal'>
                We would like to sign a contract with you!We would like to sign
                a contract with you!We would like to sign a contract with you!We
                would like to sign a contract with you!We would like to sign a
                contract with you!We would like to sign a contract with you!
              </p>
            </div>
            <div className='space-y-4'>
              <h2 className='font-bold text-[24px]'>Section 1</h2>
              <p className='text-[16px] font-normal'>
                We would like to sign a contract with you!We would like to sign
                a contract with you!We would like to sign a contract with you!We
                would like to sign a contract with you!We would like to sign a
                contract with you!We would like to sign a contract with you!
              </p>
            </div>
            <div className='space-y-4'>
              <h2 className='font-bold text-[24px]'>Section 1</h2>
              <p className='text-[16px] font-normal'>
                We would like to sign a contract with you!We would like to sign
                a contract with you!We would like to sign a contract with you!We
                would like to sign a contract with you!We would like to sign a
                contract with you!We would like to sign a contract with you!
              </p>
            </div>
            <div className='space-y-4'>
              <h2 className='font-bold text-[24px]'>Section 1</h2>
              <p className='text-[16px] font-normal'>
                We would like to sign a contract with you!We would like to sign
                a contract with you!We would like to sign a contract with you!We
                would like to sign a contract with you!We would like to sign a
                contract with you!We would like to sign a contract with you!
              </p>
            </div>
            <div className='space-y-4'>
              <h2 className='font-bold text-[24px]'>Section 1</h2>
              <p className='text-[16px] font-normal'>
                We would like to sign a contract with you!We would like to sign
                a contract with you!We would like to sign a contract with you!We
                would like to sign a contract with you!We would like to sign a
                contract with you!We would like to sign a contract with you!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='space-x-2 flex justify-center items-center py-12 '>
        <Checkbox checked={checked} onCheckedChange={handleCheckedChange} />
        <Label>
          I agree to this document and will comply with all its terms.
        </Label>
      </div>

      {/* Navigation Buttons */}
      <div className='mt-8 flex justify-between'>
        <button
          onClick={handlePrevious}
          disabled={currentStep === 1}
          className='px-4 py-2 bg-gray-200 text-gray-800 rounded disabled:opacity-50'
        >
          Previous
        </button>
        <button
          onClick={() => {
            dispatch(updateContract({ checked }));
            handleNext();
          }}
          disabled={currentStep === steps.length}
          className='px-4 py-2 bg-blue-600 text-white rounded disabled:opacity-50'
        >
          {currentStep === steps.length ? 'Finish' : 'Next'}
        </button>
      </div>
    </div>
  );
};

export default Contract;
