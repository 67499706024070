import Container from '@/components/Container';
import Header from '@/components/Header';
import { Badge } from '@/components/ui/badge';
import useTeacherById from '@/hooks/teacher/useTeacherById';
import { DownloadIcon } from 'lucide-react';

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {};

const TeacherDetail = (props: Props) => {
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const {} = useTeacherById(id as string);
  return (
    <Container>
      <Header
        title='Teachers'
        placeholder='Search Teacher'
        setSearchTerm={setSearchTerm}
      />
      <div className='flex flex-col px-6 gap-8'>
        {/* crumbs */}
        <p className='text-[#8C94A3] font-semibold'>
          Teachers &gt;
          <span className='text-[#677EEA]'>{id}</span>
        </p>

        <div className='flex gap-6'>
          {/* left section */}
          <div className='w-1/4 flex flex-col gap-6'>
            {/* profile */}
            <div className='bg-white rounded-[5px] flex flex-col items-center gap-6 p-8 shadow-xl shadow-gray-200/50'>
              <img
                className='rounded-full w-[112px] p-2 h-[112px] mx-auto bg-slate-200'
                src='/assets/images/logo.svg'
                alt='logo'
              />
              <div className='flex flex-col items-center'>
                <h1 className='text-[20px] font-semibold'>Lotfi</h1>
                <p className='text-[#8C94A3] text-sm font-normal'>joined</p>
              </div>
              <Badge variant={'active'}>Active</Badge>
            </div>

            {/* stat */}
            <div className='bg-white rounded-[5px] flex flex-col items-center gap-6 p-8 shadow-xl shadow-gray-200/50'>
              <div className='flex gap-4'>
                <div className='flex flex-col gap-2 items-center'>
                  <p className='font-normal text-center '>Total Revunues</p>
                  <p className='font-semibold '>5,000</p>
                </div>
                <div className='flex flex-col gap-2 items-center'>
                  <p className='font-normal text-center '>Current Balance</p>
                  <p className='font-semibold '>5,000</p>
                </div>
              </div>
              <div className='flex flex-col gap-2 items-center'>
                <p className='font-normal text-center '>Withdrawed Revenues</p>
                <p className='font-semibold '>5,000</p>
              </div>
            </div>

            {/* download cv */}
            <div className='bg-white rounded-[5px] flex items-center justify-between gap-6 p-6 shadow-xl shadow-gray-200/50'>
              <div className='flex gap-4'>
                <img src='/assets/icons/file.svg' alt='file' />
                <p>Download CV</p>
              </div>
              <DownloadIcon />
            </div>

            {/* information */}
            <div className='bg-white rounded-[5px] flex flex-col  gap-6 p-8 shadow-xl shadow-gray-200/50'>
              <div className='flex flex-col gap-2 items-start'>
                <p className='font-normal '>Name</p>
                <p className='font-semibold '>lotfi</p>
              </div>
              <div className='flex flex-col gap-2 items-start'>
                <p>Email</p>
                <p className='font-semibold '>lotfi@mail.com</p>
              </div>

              <div className='flex flex-col gap-2 items-start'>
                <p>Password</p>
                <p className='font-semibold '> ********</p>
              </div>
            </div>
          </div>

          {/* right section */}
          <div className='w-3/4 flex flex-col gap-6'>
            {/* action */}
            <div className='bg-white rounded-[5px] flex items-center justify-between gap-6 p-8 shadow-xl shadow-gray-200/50'>
              <div className='flex flex-col gap-2 items-start'>
                <p className='font-semibold text-xl'>Actions</p>
                <p className='font-normal text-sm text-[#8C94A3] '>
                  Report the student or send a message to the parent
                </p>
              </div>
              <div className='flex flex-col gap-2 items-center'>Ban</div>
            </div>
            {/* clases */}
            <div className='bg-white rounded-[5px] flex items-center justify-between gap-6 p-8 shadow-xl shadow-gray-200/50'>
              <div className='flex flex-col gap-2 items-start'>
                <p className='font-semibold text-xl'>Actions</p>
                <p className='font-normal text-sm text-[#8C94A3] '>
                  Report the student or send a message to the parent
                </p>
              </div>
              <div className='flex flex-col gap-2 items-center'>
                Number of classes : 10
              </div>
              <div className='flex flex-col gap-2 items-center'>
                Show classes
              </div>
            </div>

            {/* teacher inforamtion */}
            <div className='bg-white rounded-[5px] flex flex-col divide-y-2 divide-[#EEEEEE] gap-6 p-8 shadow-xl shadow-gray-200/50'>
              <div className='flex flex-col gap-2 items-start'>
                <h1 className='font-semibold text-xl'>
                  Teacher’s informations
                </h1>
                <p className='font-normal text-sm text-[#8C94A3] '>
                  Here you find the informations of the teacher
                </p>
              </div>
              <div className='flex pt-6'>
                <div className='w-1/2 flex px-4 flex-col gap-4'>
                  <div className='flex gap-2'>
                    <p className='text-[#8C94A3]'>Full Name:</p>
                    <p className='text-[#677EEA]'>lotfi</p>
                  </div>
                  <div className='flex gap-2'>
                    <p className='text-[#8C94A3]'>Phone Number:</p>
                    <p className='text-[#677EEA]'>0303030303</p>
                  </div>
                  <div className='flex gap-2'>
                    <p className='text-[#8C94A3]'>Native English Speaker:</p>
                    <p className='text-[#677EEA]'>No</p>
                  </div>
                </div>
                <div className='w-1/2 flex px-4 flex-col gap-4'>
                  <div className='flex gap-2'>
                    <p className='text-[#8C94A3]'>Email:</p>
                    <p className='text-[#677EEA]'>lotfi@mail.com</p>
                  </div>
                  <div className='flex gap-2'>
                    <p className='text-[#8C94A3]'>Country:</p>
                    <p className='text-[#677EEA]'>Algera</p>
                  </div>
                </div>
              </div>
            </div>
            {/* work experience  */}
            <div className='bg-white rounded-[5px] flex flex-col divide-y-2 divide-[#EEEEEE] gap-6 p-8 shadow-xl shadow-gray-200/50'>
              <div className='flex flex-col gap-2 items-start'>
                <h1 className='font-semibold text-xl'>Work Experience</h1>
                <p className='font-normal text-sm text-[#8C94A3] '>
                  Here you find the work experience of the teacher
                </p>
              </div>
              <div className='flex pt-6'>
                <div className='w-1/2 flex px-4 flex-col gap-4'>
                  <div className='flex gap-2'>
                    <p className='text-[#8C94A3]'>Full Name:</p>
                    <p className='text-[#677EEA]'>lotfi</p>
                  </div>
                  <div className='flex gap-2'>
                    <p className='text-[#8C94A3]'>Phone Number:</p>
                    <p className='text-[#677EEA]'>0303030303</p>
                  </div>
                  <div className='flex gap-2'>
                    <p className='text-[#8C94A3]'>Native English Speaker:</p>
                    <p className='text-[#677EEA]'>No</p>
                  </div>
                </div>
                <div className='w-1/2 flex px-4 flex-col gap-4'>
                  <div className='flex gap-2'>
                    <p className='text-[#8C94A3]'>Email:</p>
                    <p className='text-[#677EEA]'>lotfi@mail.com</p>
                  </div>
                  <div className='flex gap-2'>
                    <p className='text-[#8C94A3]'>Country:</p>
                    <p className='text-[#677EEA]'>Algera</p>
                  </div>
                </div>
              </div>
            </div>
            {/* work experience  */}
            <div className='bg-white rounded-[5px] flex flex-col divide-y-2 divide-[#EEEEEE] gap-6 p-8 shadow-xl shadow-gray-200/50'>
              <div className='flex flex-col gap-2 items-start'>
                <h1 className='font-semibold text-xl'>Certificates</h1>
                <p className='font-normal text-sm text-[#8C94A3] '>
                  Here you find the certificates of the teacher
                </p>
              </div>
              <div className='flex pt-6'>
                <div className='w-1/2 flex px-4 flex-col gap-4'>
                  <div className='flex gap-2'>
                    <p className='text-[#8C94A3]'>Full Name:</p>
                    <p className='text-[#677EEA]'>lotfi</p>
                  </div>
                  <div className='flex gap-2'>
                    <p className='text-[#8C94A3]'>Phone Number:</p>
                    <p className='text-[#677EEA]'>0303030303</p>
                  </div>
                  <div className='flex gap-2'>
                    <p className='text-[#8C94A3]'>Native English Speaker:</p>
                    <p className='text-[#677EEA]'>No</p>
                  </div>
                </div>
                <div className='w-1/2 flex px-4 flex-col gap-4'>
                  <div className='flex gap-2'>
                    <p className='text-[#8C94A3]'>Email:</p>
                    <p className='text-[#677EEA]'>lotfi@mail.com</p>
                  </div>
                  <div className='flex gap-2'>
                    <p className='text-[#8C94A3]'>Country:</p>
                    <p className='text-[#677EEA]'>Algera</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TeacherDetail;
