import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateMeet } from '@/redux/slices/becomeATeacherSlice';
import { RootState } from '@/redux/store';
import DatePicker from '@/components/DatePicker';
import TimePicker from '@/components/TimePicker';
import { Label } from '@/components/ui/label';

type Props = {
  currentStep: number;
  handleNext: () => void;
  handlePrevious: () => void;
  steps: Array<{ label: string; desc: string }>;
};

const Meet = ({ currentStep, handleNext, handlePrevious, steps }: Props) => {
  const dispatch = useDispatch();

  // Access the current date from the Redux store
  const { date, time } = useSelector(
    (state: RootState) => state.becomeATeacher.meet
  );

  // Function to handle date selection
  const handleDateChange = (selectedDate: Date | undefined) => {
    if (selectedDate) {
      // Dispatch the action to update the Redux state
      dispatch(updateMeet({ date: selectedDate.toISOString() }));
    }
  };

  const handleTimeChange = (selectedTime: string) => {
    dispatch(updateMeet({ time: selectedTime }));
  };

  return (
    <div>
      <div className='flex gap-2 divide-x-2 divide-[#EEEEEE]'>
        <div className='w-1/3 flex flex-col gap-8 divide-y-2 divide-[#EEEEEE] p-4'>
          <p>
            We would like to schedule an interview with you! Pick a time & date!
          </p>

          <div className='space-y-4 py-4'>
            <div className='flex gap-3 items-center'>
              <img src='assets/icons/Clock.svg' alt='Clock' />
              <p>30 minutes</p>
            </div>
            <div className='flex gap-3 items-start'>
              <img src='assets/icons/Info.svg' alt='Info' />
              <p>
                this is an on-site interview. Please bring your CV and any other
                documents required for work contract
              </p>
            </div>
          </div>
        </div>

        <div className='w-2/3 p-8 flex flex-col gap-8'>
          <div className='flex gap-4 items-center'>
            <Label>Date:</Label>
            <div className=' w-1/2'>
              <DatePicker
                date={date ? new Date(date) : undefined}
                onPickDate={handleDateChange}
              />
            </div>
          </div>

          <div className='flex gap-4 items-center'>
            <Label>Time:</Label>
            <TimePicker
              value={time}
              onValueChange={(value: string) => {
                handleTimeChange(value);
              }}
            />
          </div>
        </div>
      </div>
      <div className='mt-8 flex justify-between'>
        <button
          onClick={handlePrevious}
          disabled={currentStep === 1}
          className='px-4 py-2 bg-gray-200 text-gray-800 rounded disabled:opacity-50'
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          className='px-4 py-2 bg-blue-600 text-white rounded disabled:opacity-50'
        >
          {currentStep === steps.length ? 'Finish' : 'Next'}
        </button>
      </div>
    </div>
  );
};

export default Meet;
