import {
  Form,
  FormField,
  FormItem,
  FormControl,
  FormMessage,
} from '@/components/ui/form';
import { Checkbox } from '@/components/ui/checkbox';

export default function StepContract({ formValues }: any) {
  return (
    <div className='w-[756px]  px-28 flex flex-col justify-start items-center   '>
      <h1 className='font-semibold text-[40px] '>Terms of use</h1>
      <p>Scroll through the terms of use below</p>
      <div className='w-full space-y-4 mt-8'>
        <Form {...formValues}>
          <FormField
            control={formValues.control}
            name='signedContract'
            render={({ field }) => (
              <FormItem className=''>
                <FormControl>
                  <div className='p-4 h-[600px] space-y-8   overflow-scroll'>
                    <div className='bg-slate-200 h-px w-full' />
                    <div className='space-y-4'>
                      <h2 className='font-bold text-[24px]'>Section 1</h2>
                      <p className='text-[16px] font-normal'>
                        We would like to sign a contract with you!We would like
                        to sign a contract with you!We would like to sign a
                        contract with you!We would like to sign a contract with
                        you!We would like to sign a contract with you!We would
                        like to sign a contract with you!
                      </p>
                    </div>
                    <div className='space-y-4'>
                      <h2 className='font-bold text-[24px]'>Section 1</h2>
                      <p className='text-[16px] font-normal'>
                        We would like to sign a contract with you!We would like
                        to sign a contract with you!We would like to sign a
                        contract with you!We would like to sign a contract with
                        you!We would like to sign a contract with you!We would
                        like to sign a contract with you!
                      </p>
                    </div>
                    <div className='space-y-4'>
                      <h2 className='font-bold text-[24px]'>Section 1</h2>
                      <p className='text-[16px] font-normal'>
                        We would like to sign a contract with you!We would like
                        to sign a contract with you!We would like to sign a
                        contract with you!We would like to sign a contract with
                        you!We would like to sign a contract with you!We would
                        like to sign a contract with you!
                      </p>
                    </div>
                    <div className='space-y-4'>
                      <h2 className='font-bold text-[24px]'>Section 1</h2>
                      <p className='text-[16px] font-normal'>
                        We would like to sign a contract with you!We would like
                        to sign a contract with you!We would like to sign a
                        contract with you!We would like to sign a contract with
                        you!We would like to sign a contract with you!We would
                        like to sign a contract with you!
                      </p>
                    </div>
                    <div className='space-y-4'>
                      <h2 className='font-bold text-[24px]'>Section 1</h2>
                      <p className='text-[16px] font-normal'>
                        We would like to sign a contract with you!We would like
                        to sign a contract with you!We would like to sign a
                        contract with you!We would like to sign a contract with
                        you!We would like to sign a contract with you!We would
                        like to sign a contract with you!
                      </p>
                    </div>
                    <div className='bg-slate-200 h-px w-full' />
                    <div className='flex gap-3 items-center'>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                      <p>I have read and agree to the terms</p>
                    </div>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
      </div>
    </div>
  );
}
