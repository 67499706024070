import { getCommunes } from '@/api/wilaya';
import { useState, useEffect } from 'react';

type Commune = {
  name: string;
  communes: string[];
};

const useCommunes = (wilaya: string) => {
  const [communesList, setCommunesList] = useState<Commune>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // Fetch commune data based on the "wilaya" parameter
    const fetchCommunes = async () => {
      try {
        setLoading(true);
        if (!wilaya) {
          setCommunesList(undefined);
          setLoading(false);
          return;
        }
        const response = await getCommunes(wilaya);
        setCommunesList(response);
      } catch (error) {
        console.error('Error fetching communes:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCommunes();
  }, [wilaya]);

  return { communesList, loading };
};

export default useCommunes;
