import { addClient } from '@/api/client';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

const useAddClient = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const addClientForm = useForm({
    defaultValues: {
      fullname: '',
      commercial_name: '',
      phone: '',
      company: '',
      email: '',
      wilaya: '',
      commune: '',
      positionGps: '',
      tradeRegisterNumber: '',
      fiscalId: '',
      statisticalId: '',
      impositionArticleNumber: '',
    },
  });

  const onSubmit = async (
    client: Client,
    onSuccess: (message: string) => void,
    onError: (message: string) => void
  ) => {
    setIsSubmitting(true);

    // format phone, if it starts with zero remove it else keep evertything as it is
    if (client.phone.startsWith('0')) {
      client.phone = '+213' + client.phone.slice(1);
    }

    console.log(client);

    try {
      const response = await addClient(client);
      console.log('response ', response);
      if (
        response.data.code === 'P2002' &&
        response.data.meta &&
        response.data.meta.target
      ) {
        // Extract the duplicated field from the meta data.
        const duplicatedField = response.data.meta.target.join(', ');
        // Log the error details for debugging purposes.

        // Return an error message in French indicating the duplicated field.
        setIsSubmitting(false);
        return onError(
          `Erreur : Le champ ${duplicatedField} est déjà utilisé. Veuillez en choisir un autre.`
        );
      } else if (response.status === 201) {
        // If no error, call the success handler.
        setIsSubmitting(false);
        onSuccess('Client ajouté avec succès');
      }
    } catch (error) {
      setIsSubmitting(false);
      onError("Erreur lors de l'ajout du personnel");
    }
  };

  return { isSubmitting, addClientForm, onSubmit };
};

export default useAddClient;
