import { useNavigate } from 'react-router-dom';
import { SignupForm } from './SignupForm';
import Layout from '@/components/Layout';

export default function StudentSignup() {
  const navigate = useNavigate();
  return (
    <Layout>
      <div className=''>
        <img className='py-4' src='/assets/images/logo.svg' alt='logo' />
        <div className='container bg-white  relative    flex flex-col items-center justify-center '>
          {/* login card */}

          <SignupForm />
        </div>
      </div>
    </Layout>
  );
}
