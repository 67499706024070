import { useStudents } from '@/hooks/student/useStudent';
import { columns } from '@/pages/student/studentList/columns';
import { DataTable } from '@/pages/student/studentList/data-table';
import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {};

const Students = (props: Props) => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [pageIndex, setPageIndex] = useState(0); // pageIndex is 0-based
  const [pageSize, setPageSize] = useState(10);

  // Fetch clients from the API using pageIndex and pageSize
  const { students, refresh, meta, loading, error } = useStudents(
    pageIndex + 1,
    pageSize,
    searchTerm
  );

  // Initialize table with pagination
  const table = useReactTable({
    data: students,
    columns: [
      ...columns,
      {
        id: 'actions',
        cell: ({ row }) => {
          return <div className=' flex justify-end'>Details</div>;
        },
      },
    ],
    pageCount: meta ? meta.lastPage : -1, // Set total page count from meta
    manualPagination: true, // Pagination is handled externally (we handle it with useClients)
    state: {
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  // Handle page size change
  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize); // Update the page size state
    table.setPageSize(newPageSize); // Also update the table's page size
  };

  // Handle page index change (for next/previous page)
  const handlePageChange = (newPageIndex: number) => {
    setPageIndex(newPageIndex); // Update the page index state
    table.setPageIndex(newPageIndex); // Update the table's page index
  };
  return (
    <div className=' h-full'>
      <DataTable
        table={table}
        columns={columns}
        loading={loading}
        error={error}
        title='Students'
        desc='Here you can find the students and their details'
      />
    </div>
  );
};

export default Students;
