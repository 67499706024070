import api from './baseApi';

export const addClient = async (client: Client) => {
  try {
    const response = await api.post('/users/client', client);
    return response;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

export const getClients = async ({
  page = 1,
  perPage = 10,
  searchTerm,
  selectedWilaya,
}: {
  page?: number;
  perPage?: number;
  searchTerm?: string;
  selectedWilaya?: string;
}) => {
  try {
    let query = `?page=${page}&perPage=${perPage}`;
    if (searchTerm) {
      query += `&fullname=${searchTerm}`;
    }

    if (selectedWilaya) {
      query += `&wilaya=${selectedWilaya}`;
    }
    const response = await api.get(`/users/list/client${query}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching clients:', error);
    return [];
  }
};

export const deleteClient = async (clientId: string) => {
  try {
    const response = await api.delete(`/users/${clientId}`);
    return response;
  } catch (error) {
    console.error('Error deleting client:', error);
  }
};
