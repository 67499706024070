import React from 'react';
import { Button } from './ui/button';
import { useNavigate } from 'react-router-dom';

interface AddButtonProps {
  type: 'client' | 'employee' | 'collector' | 'collectionorder';
}

const AddButton: React.FC<AddButtonProps> = ({ type }) => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate(`/${type}/add`)}
      className='px-20 py-3 mt-auto bg-primary text-white text-[16px] font-medium gap-3'
    >
      Add {type}
    </Button>
  );
};

export default AddButton;
