import React from 'react';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className='relative flex flex-col min-h-screen bg-white'>
      {/* Navbar */}
      {/* <header className='bg-blue-600 text-white p-4'>
        <nav className='container mx-auto'>Navbar</nav>
      </header> */}

      <div>
        <img
          className='absolute top-0 right-0'
          src='/assets/images/top.svg'
          alt=''
        />
        <img
          className='absolute top-[30%] left-0'
          src='/assets/images/mid.svg'
          alt=''
        />
        <img
          className='absolute z-40 bottom-0 right-0'
          src='/assets/images/low.svg'
          alt=''
        />
      </div>

      {/* Content */}
      <main className='max-w-screen-2xl  flex-grow  container bg-white mx-auto py-8'>
        {children}
      </main>

      {/* Footer */}
      <footer className='bg-[#5969B3] z-50  mt-auto h-[234px]'>
        <div className='container gap-4 flex justify-center items-center flex-col h-full mx-auto text-center'>
          <img src='/assets/images/logoLight.svg' alt='logo' />
          <p className='text-white'>© 2023 - Lansly. All rights reserved</p>
        </div>
      </footer>
    </div>
  );
};

export default Layout;
