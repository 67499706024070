import {
  deleteCvFile,
  updateTeacherInfo,
} from '@/redux/slices/becomeATeacherSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/store'; // Adjust based on your store setup
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Checkbox } from '@/components/ui/checkbox';
import WorkExperienceCard from '@/components/WorkExperienceCard';
import AddWorkExperience from '@/components/AddWorkExperience';
import AddCertification from '@/components/AddCertification';
import { Trash } from 'lucide-react';
import DiplomaCard from '@/components/DiplomaCard';
import AddDiploma from '@/components/AddDiploma';

type Props = {
  currentStep: number;
  handleNext: () => void;
  handlePrevious: () => void;
  steps: Array<{ label: string; desc: string }>;
};

const Informations = ({
  currentStep,
  handleNext,
  handlePrevious,
  steps,
}: Props) => {
  const dispatch = useDispatch();

  const {
    name,
    email,
    phone,
    country,
    password,
    nativeSpeaker,
    workExperience,
    teachingCertificates,
    diplomas,
    cvFile,
  } = useSelector((state: RootState) => state.becomeATeacher.teacherInfo);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(updateTeacherInfo({ [name]: value }));
  };

  const handleCheckboxChange = (value: boolean) => {
    dispatch(updateTeacherInfo({ nativeSpeaker: value }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    dispatch(updateTeacherInfo({ cvFile: file }));
  };

  return (
    <div>
      <div className='flex gap-20 px-20 py-14'>
        {/* header */}
        <div className='w-1/3'>
          <h1 className='text-[24px] font-semibold'>Basic informations</h1>
          <p className='text-[#707070] text-base'>
            Some information about the section could go here and a brief
            description of how this might be used.
          </p>
        </div>

        {/* form */}
        <div className='w-2/3 space-y-4'>
          {/* name phone */}
          <div className='flex gap-10'>
            <div className='flex flex-col gap-2 w-1/2'>
              <Label>Full Name</Label>
              <Input
                name='name'
                value={name}
                onChange={handleInputChange}
                placeholder='Name'
              />
            </div>

            <div className='flex flex-col gap-2 w-1/2'>
              <Label>Phone</Label>
              <Input
                name='phone'
                value={phone}
                onChange={handleInputChange}
                placeholder='Phone'
              />
            </div>
          </div>

          {/* email country */}
          <div className='flex gap-10'>
            <div className='flex flex-col gap-2 w-1/2'>
              <Label>Email</Label>
              <Input
                name='email'
                value={email}
                onChange={handleInputChange}
                placeholder='Email'
              />
            </div>

            <div className='flex flex-col gap-2 w-1/2'>
              <Label>Country</Label>
              <Input
                name='country'
                value={country}
                onChange={handleInputChange}
                placeholder='Country'
              />
            </div>
          </div>

          <div className='flex flex-col gap-2 w-1/2 pr-4'>
            <Label>Password</Label>
            <Input
              name='password'
              value={password}
              onChange={handleInputChange}
              placeholder='Password'
            />
          </div>

          <div className='space-y-2'>
            <p>Are you a native English speaker?</p>
            <div className='flex gap-8'>
              <div className='space-x-2 flex items-center'>
                <Checkbox
                  id='yes'
                  checked={nativeSpeaker}
                  onCheckedChange={() => handleCheckboxChange(true)}
                />
                <label htmlFor='yes'>Yes</label>
              </div>
              <div className='space-x-2 flex items-center'>
                <Checkbox
                  id='no'
                  checked={!nativeSpeaker}
                  onCheckedChange={() => handleCheckboxChange(false)}
                />
                <label htmlFor='no'>No</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img src='assets/icons/line.svg' alt='line' />

      {/* work experiece */}
      <div className='flex gap-20 px-20 py-14'>
        {/* header */}
        <div className='w-1/3'>
          <h1 className='text-[24px] font-semibold'>Work experiece</h1>
          <p className='text-[#707070] text-base'>
            Some information about the section could go here and a brief
            description of how this might be used.
          </p>
        </div>

        {/* form */}
        <div className='w-2/3 space-y-4'>
          {workExperience.length === 0 ? (
            <div className='w-full h-full  flex justify-center items-center'>
              <AddWorkExperience />
            </div>
          ) : (
            <div className='flex flex-col justify-center gap-8'>
              <div className='grid grid-cols-3 gap-4 '>
                {workExperience.map((exp, index) => (
                  <div key={index} className='space-y-4'>
                    <WorkExperienceCard workExperience={exp} index={index} />
                  </div>
                ))}
              </div>
              <div className='mx-auto'>
                <AddWorkExperience />
              </div>
            </div>
          )}
        </div>
      </div>

      <img src='assets/icons/line.svg' alt='line' />

      {/* CV */}
      <div className='flex gap-20 px-20 py-14'>
        {/* header */}
        <div className='w-1/3'>
          <h1 className='text-[24px] font-semibold'>CV</h1>
          <p className='text-[#707070] text-base'>
            Some information about the section could go here and a brief
            description of how this might be used.
          </p>
        </div>

        {/* form */}
        <div className='w-2/3 space-y-4'>
          {/* input to upload cvfile and handle input change  */}
          <div className='flex gap-10'>
            <div className='flex flex-col gap-2 w-1/2'>
              <Label>Upload CV</Label>
              <Input type='file' name='cvFile' onChange={handleFileChange} />
              {cvFile && (
                <div className='flex items-center mt-2'>
                  <span className='mr-2'>{cvFile.name}</span>
                  <Trash
                    color='#FF0000'
                    size={20}
                    onClick={() => dispatch(deleteCvFile())}
                    className='cursor-pointer'
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <img src='assets/icons/line.svg' alt='line' />

      {/* Teaching Certificates */}
      <div className='flex gap-20 px-20 py-14'>
        {/* header */}
        <div className='w-1/3'>
          <h1 className='text-[24px] font-semibold'>Teaching Certificates</h1>
          <p className='text-[#707070] text-base'>
            Some information about the section could go here and a brief
            description of how this might be used.
          </p>
        </div>

        {/* form */}
        <div className='w-2/3 space-y-4'>
          {teachingCertificates.length === 0 ? (
            <div className='w-full h-full  flex justify-center items-center'>
              <AddCertification />
            </div>
          ) : (
            <div className='flex flex-col justify-center gap-8'>
              <div className='grid grid-cols-3 gap-4 '>
                {teachingCertificates.map((exp, index) => (
                  <div key={index} className='space-y-4'>
                    <WorkExperienceCard workExperience={exp} index={index} />
                  </div>
                ))}
              </div>
              <div className='mx-auto'>
                <AddWorkExperience />
              </div>
            </div>
          )}
        </div>
      </div>

      <img src='assets/icons/line.svg' alt='line' />

      {/* Academic Diploma */}
      <div className='flex gap-20 px-20 py-14'>
        {/* header */}
        <div className='w-1/3'>
          <h1 className='text-[24px] font-semibold'>Academic Diploma</h1>
          <p className='text-[#707070] text-base'>
            Some information about the section could go here and a brief
            description of how this might be used.
          </p>
        </div>

        {/* form */}
        <div className='w-2/3 space-y-4'>
          {diplomas.length === 0 ? (
            <div className='w-full h-full  flex justify-center items-center'>
              <AddDiploma />
            </div>
          ) : (
            <div className='flex flex-col justify-center gap-8'>
              <div className='grid grid-cols-3 gap-4 '>
                {diplomas.map((exp, index) => (
                  <div key={index} className='space-y-4'>
                    <WorkExperienceCard workExperience={exp} index={index} />
                  </div>
                ))}
              </div>
              <div className='mx-auto'>
                <AddWorkExperience />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className='mt-8 flex justify-between'>
        <button
          onClick={handlePrevious}
          disabled={currentStep === 1}
          className='px-4 py-2 bg-gray-200 text-gray-800 rounded disabled:opacity-50'
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          disabled={currentStep === steps.length}
          className='px-4 py-2 bg-blue-600 text-white rounded disabled:opacity-50'
        >
          {currentStep === steps.length ? 'Finish' : 'Next'}
        </button>
      </div>
    </div>
  );
};

export default Informations;
