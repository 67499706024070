import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MobileMenu from './MobileMenu';
import { RoleBasedComponent } from '@/utils/RoleBasedComponent';
const Sidebar = () => {
  const [currentRoute, setCurrentRoute] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location]);

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  return (
    <>
      <div className='hidden md:flex md:w-[269px] z-40    flex-col gap-4 md:fixed h-screen bg-[#5669C3] pt-8 font-normal text-[14px]'>
        <div className='flex justify-center items-center'>
          <img src='/assets/images/logowhite.svg' alt='logo' />
        </div>
        <div className='flex  pt-8 flex-col gap-2'>
          {/* teacher */}
          <RoleBasedComponent allowedRoles={['admin', 'supervisor']}>
            <div
              onClick={() => handleNavigate('/teachers')}
              className={`flex text-white text-[16px]  gap-5 px-8 py-4 items-center cursor-pointer hover:bg-muted/50 transition duration-250 ease-in-out ${
                currentRoute === '/teachers' ||
                currentRoute === '/teacher/add' ||
                currentRoute === '/'
                  ? 'font-medium  bg-gradient-to-r from-[#677EEA] to-[#ffd00000] '
                  : ''
              }`}
            >
              <img src='/assets/icons/teacher.svg' alt='teacher' />
              <span className='flex justify-start items-start'>Teachers</span>
            </div>
          </RoleBasedComponent>
          {/* classe */}
          <RoleBasedComponent allowedRoles={['admin', 'supervisor']}>
            <div
              onClick={() => handleNavigate('/classes')}
              className={`flex text-white text-[16px]  gap-5 px-8 py-4 items-center cursor-pointer hover:bg-muted/50 transition duration-250 ease-in-out ${
                currentRoute === '/classes' || currentRoute === '/classes/add'
                  ? 'font-medium  bg-gradient-to-r from-[#677EEA] to-[#ffd00000] '
                  : ''
              }`}
            >
              <img src='/assets/icons/class.svg' alt='class' />
              <span className='flex justify-start items-start'>Classes</span>
            </div>
          </RoleBasedComponent>
          {/* students */}
          <RoleBasedComponent allowedRoles={['admin', 'supervisor']}>
            <div
              onClick={() => handleNavigate('/students')}
              className={`flex text-white text-[16px]  gap-5 px-8 py-4 items-center cursor-pointer hover:bg-muted/50 transition duration-250 ease-in-out ${
                currentRoute === '/students' || currentRoute === '/student/add'
                  ? 'font-medium  bg-gradient-to-r from-[#677EEA] to-[#ffd00000] '
                  : ''
              }`}
            >
              <img src='/assets/icons/student.svg' alt='student' />
              <span className='flex justify-start items-start'>Students</span>
            </div>
          </RoleBasedComponent>
          {/* consultant */}
          <RoleBasedComponent allowedRoles={['admin', 'supervisor']}>
            <div
              onClick={() => handleNavigate('/consultants')}
              className={`flex text-white text-[16px]  gap-5 px-8 py-4 items-center cursor-pointer hover:bg-muted/50 transition duration-250 ease-in-out ${
                currentRoute === '/consultants' ||
                currentRoute === '/consultant/add'
                  ? 'font-medium  bg-gradient-to-r from-[#677EEA] to-[#ffd00000] '
                  : ''
              }`}
            >
              <img src='/assets/icons/consultant.svg' alt='consultant' />
              <span className='flex justify-start items-start'>
                Consultants
              </span>
            </div>
          </RoleBasedComponent>
        </div>
      </div>

      <div className='md:hidden'>
        <MobileMenu />
      </div>
    </>
  );
};

export default Sidebar;
