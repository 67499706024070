import Spinner from '@/components/Spinner';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '@/components/ui/select';

interface TimePickerProps {
  value: string;
  onValueChange: (value: string) => void;
}

const TimePicker = ({ value, onValueChange }: TimePickerProps) => {
  // Function to generate time range from 00:00 to 23:30 with a 30-minute step
  const generateTimeRange = (): string[] => {
    const times: string[] = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = String(hour).padStart(2, '0');
        const formattedMinute = String(minute).padStart(2, '0');
        times.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return times;
  };

  const timeRange = generateTimeRange();

  return (
    <Select onValueChange={onValueChange} defaultValue={value}>
      <SelectTrigger className='h-[44px] font-normal flex justify-between w-full rounded-[5px] border border-[#C9C9C9] bg-white px-3 py-1 text-sm shadow-sm transition-colors placeholder:text-muted-foreground/50 disabled:cursor-not-allowed disabled:opacity-50 md:w-1/2'>
        {value ? value : <span className='text-[#C9C9C9]'>00:00</span>}
      </SelectTrigger>
      <SelectContent>
        {timeRange.map((item: string) => (
          <SelectItem key={item} value={item}>
            {item}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default TimePicker;
