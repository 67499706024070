import Navbar from '@/components/Navbar';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';
import Informations from './steps/Informations';
import Meet from './steps/Meet';
import Contract from './steps/Contract';
import Welcome from './steps/Welcome';

type Props = {};

const BecomeATeacher = (props: Props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const steps = [
    {
      label: 'Teacher informations',
      desc: 'Fill the informations below to apply as a Teacher',
      isCompleted: false,
      isActive: true,
      component: Informations,
    },
    {
      label: 'Choosing a meet',
      desc: 'Pick a time slot for the interview',
      isCompleted: false,
      isActive: false,
      component: Meet,
    },
    {
      label: 'Signing contract',
      desc: 'Read the contract details shown below',
      isCompleted: false,
      isActive: false,
      component: Contract,
    },
    {
      label: 'Welcome!',
      isCompleted: false,
      desc: '',
      isActive: false,
      component: Welcome,
    },
  ];

  const handleNext = () => {
    if (currentStep < steps.length) {
      setCurrentStep((prev) => prev + 1);
      const updatedSteps = [...steps];
      updatedSteps[currentStep - 1].isCompleted = true;
      if (currentStep < steps.length) {
        updatedSteps[currentStep].isActive = true;
      }
    }
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };

  const CurrentStepComponent = steps[currentStep - 1].component;

  return (
    <>
      <Navbar />
      <div className='max-w-screen-xl mx-auto'>
        <img className='py-4' src='assets/images/logo.svg' alt='logo' />

        <div className='w-full max-w-screen-xl mx-auto flex flex-col gap-12 mt-8'>
          <div className='flex gap-12'>
            <div className='w-1/3 flex flex-col justify-center items-start'>
              <h1 className='text-[32px] font-semibold'>
                {steps[currentStep - 1].label}
              </h1>
              <p className='text-[#4C4C4D]'>{steps[currentStep - 1].desc}</p>
            </div>
            <ol className='w-2/3 flex items-center  mb-8'>
              {steps.map((step, index) => (
                <li
                  key={step.label}
                  className='flex items-center w-full last:flex-1'
                >
                  <div className='flex flex-col items-center flex-1'>
                    <div
                      className={`flex items-center justify-center w-8 h-8 rounded-full border-2 ${
                        index + 1 === currentStep || step.isCompleted
                          ? 'bg-blue-600 border-blue-600'
                          : 'border-gray-300'
                      }`}
                    >
                      {step.isCompleted ? (
                        <Check className='w-5 h-5 text-white' />
                      ) : (
                        <span
                          className={`text-sm font-medium ${
                            index + 1 === currentStep
                              ? 'text-white'
                              : 'text-gray-500'
                          }`}
                        >
                          {index + 1}
                        </span>
                      )}
                    </div>
                    <span
                      className={`mt-2 text-sm font-medium text-center ${
                        index + 1 === currentStep
                          ? 'text-blue-600'
                          : 'text-gray-500'
                      }`}
                    >
                      {step.label}
                    </span>
                  </div>
                  {index < steps.length - 1 && (
                    <div
                      className={`flex-1 h-0.5 mx-2 ${
                        step.isCompleted ? 'bg-blue-600' : 'bg-gray-300'
                      }`}
                    ></div>
                  )}
                </li>
              ))}
            </ol>
          </div>

          <motion.div
            key={currentStep}
            className='bg-white rounded-[12px] p-8 mb-12'
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <CurrentStepComponent
              currentStep={currentStep}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              steps={steps}
            />
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default BecomeATeacher;
