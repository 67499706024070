import AddButton from '@/components/AddButton';
import Container from '@/components/Container';
import Header from '@/components/Header';
import React, { useState } from 'react';
import { columns } from './clientList/columns';
import { DataTable } from './clientList/data-table';
import WilayaDropdown from '@/components/WilayaDropdown';
import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { DataTablePagination } from '@/components/Pagination';
import { DataTableViewOptions } from '@/components/ColumnToggle';
import { useClients } from '@/hooks/clients/useClients';
import Delete from './clientList/Delete';

type PropsClients = {};

const Clients: React.FC<PropsClients> = () => {
  const [selectedWilaya, setSelectedWilaya] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState('');
  const [pageIndex, setPageIndex] = useState(0); // pageIndex is 0-based
  const [pageSize, setPageSize] = useState(10);

  // Fetch clients from the API using pageIndex and pageSize
  const { clients, refresh, meta, loading, error } = useClients(
    pageIndex + 1,
    pageSize,
    searchTerm,
    selectedWilaya
  );

  // Initialize table with pagination
  const table = useReactTable({
    data: clients,
    columns: [
      ...columns,
      {
        id: 'actions',
        cell: ({ row }) => (
          <div className=' flex justify-end'>
            <Delete
              clientId={row.original.id?.toString()}
              onDelete={refresh} // Toggle refresh state
            />
          </div>
        ),
      },
    ],
    pageCount: meta ? meta.lastPage : -1, // Set total page count from meta
    manualPagination: true, // Pagination is handled externally (we handle it with useClients)
    state: {
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    getPaginationRowModel: getPaginationRowModel(),
    getCoreRowModel: getCoreRowModel(),
  });

  // Handle page size change
  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize); // Update the page size state
    table.setPageSize(newPageSize); // Also update the table's page size
  };

  // Handle page index change (for next/previous page)
  const handlePageChange = (newPageIndex: number) => {
    setPageIndex(newPageIndex); // Update the page index state
    table.setPageIndex(newPageIndex); // Update the table's page index
  };
  return (
    <Container>
      <Header placeholder='Search Client name' setSearchTerm={setSearchTerm} />
      <div className='flex flex-col gap-2 p-4  h-full'>
        <div className='flex justify-between items-center'>
          <h1 className='font-semibold text-[18px]'>Client List</h1>

          <div className='flex items-center gap-4'>
            <WilayaDropdown
              isFilter={true}
              value={selectedWilaya}
              onValueChange={(value) =>
                value === 'all'
                  ? setSelectedWilaya('')
                  : setSelectedWilaya(value)
              }
            />
            <AddButton type='client' />
          </div>
        </div>

        {/* client table  */}
        <div className='flex-grow overflow-y-scroll  py-2'>
          <DataTable
            table={table}
            columns={columns}
            loading={loading}
            error={error}
          />
        </div>

        {/* pagination and column toggle */}
        <div className='mt-auto flex justify-between pt-2'>
          <div>
            <DataTableViewOptions table={table} />
          </div>
          <div className='flex justify-end w-full '>
            <DataTablePagination
              table={table}
              onPageChange={handlePageChange} // Handle page index change
              onPageSizeChange={handlePageSizeChange} // Handle page size change
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Clients;
