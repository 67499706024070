import { useState } from 'react';
import { format, addDays, subDays } from 'date-fns';
import { Button } from '@/components/ui/button';

const Meeting = ({ formValues }: any) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState<string | null>(null);

  // Generate a week's worth of dates
  const days = Array.from({ length: 6 }, (_, i) => addDays(currentDate, i));

  // Generate times
  const times = [
    '08:00 AM',
    '08:30 AM',
    '09:00 AM',
    '09:30 AM',
    '10:00 AM',
    '11:00 AM',
    // Add more times as needed
  ];

  const handlePreviousDays = () => setCurrentDate(subDays(currentDate, 6));
  const handleNextDays = () => setCurrentDate(addDays(currentDate, 6));

  return (
    <div className='w-[1100px] px-10  mx-auto bg-white  space-y-6'>
      {/* Header */}
      <div className='fixed top-14 left-[21%] w-[1100px] bg-white border border-[#C0C0C0]/[0.8] rounded-[38px]  py-5'>
        <div className='flex  justify-between items-center px-8'>
          <p className='text-base text-gray-700'>
            We would like to schedule an interview with you! Pick a time & date!
          </p>
          <p className='text-gray-500'>Call Duration: 25 min</p>
        </div>
      </div>

      {/* Day Navigation */}
      <div className='flex justify-between items-center '>
        <Button
          variant='ghost'
          onClick={handlePreviousDays}
          className='text-blue-500 text-base hover:underline'
        >
          &lt; Previous 6 days
        </Button>
        <Button
          variant='ghost'
          onClick={handleNextDays}
          className='text-blue-500 text-base hover:underline'
        >
          Next 6 days &gt;
        </Button>
      </div>

      <div className='bg-gray-300 h-px w-full' />

      {/* Days and Time Slots */}
      <div className='grid grid-cols-6 '>
        {days.map((day, dayIndex) => (
          <div key={dayIndex} className='space-y-2'>
            <p className='text-center font-medium text-gray-700'>
              {format(day, 'EEEE')}
            </p>
            <p className='text-center text-sm text-gray-500'>
              {format(day, 'MMM dd')}
            </p>
            <div className='bg-gray-300 h-px w-full' />
            <div className='flex flex-col  items-center  space-y-2'>
              {times.map((time, timeIndex) => (
                <Button
                  key={timeIndex}
                  variant='outline'
                  className={` px-8 py-1 text-sm rounded-lg border ${
                    selectedTime === time
                      ? 'bg-blue-500 text-white'
                      : 'text-blue-500 border-blue-500'
                  } hover:bg-blue-100`}
                  onClick={() => setSelectedTime(time)}
                >
                  {time}
                </Button>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Meeting;
