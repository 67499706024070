import { Button } from '@/components/ui/button';

import { useNavigate } from 'react-router-dom';
import { LoginForm } from './LoginForm';
import Navbar from '@/components/Navbar';
import Layout from '@/components/Layout';

export default function StudentLogin() {
  const navigate = useNavigate();
  return (
    <Layout>
      <img className='py-4' src='/assets/images/logo.svg' alt='logo' />

      {/* login card */}
      <div className='w-full  flex justify-center '>
        <div className='inverted-circle bg-white border border-[#C0C0C0]/[0.8] rounded-[38px]  w-[756px] p-28'>
          <div className='mx-auto flex  flex-col justify-center items-center     '>
            <h1 className='font-semibold text-[48px] '>Log in</h1>
            <p>Log in to your account</p>

            <div className='w-[100%] mt-12'>
              <LoginForm />
            </div>

            <p className='mb-8 lg:mb-0 mx-auto mt-8 flex gap-1 text-center text-xs text-foreground'>
              Don't have an account?{' '}
              <div
                onClick={() => navigate('/student/platform/signup')}
                className='underline text-primary underline-offset-4 hover:text-primary'
              >
                Sign up
              </div>{' '}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
