import React from 'react';
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { PasswordInput } from '@/components/ui/password-input';

export default function StepInformation({ formValues }: any) {
  return (
    <div className=' w-[756px]  px-28 flex  flex-col justify-start items-center   '>
      <h1 className='font-semibold text-[40px] '>Sign up</h1>
      <p>Create new account</p>
      <div className='w-full space-y-4'>
        <Form {...formValues}>
          <FormField
            control={formValues.control}
            name='fullname'
            render={({ field }) => (
              <FormItem className=''>
                <FormLabel>
                  <span className='text-sm font-medium'>Full name</span>
                </FormLabel>
                <FormControl>
                  <Input placeholder='Full name' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={formValues.control}
            name='email'
            render={({ field }) => (
              <FormItem className=''>
                <FormLabel>
                  <span className='text-sm font-medium'>Email</span>
                </FormLabel>
                <FormControl>
                  <Input placeholder='Email' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={formValues.control}
            name='phone'
            render={({ field }) => (
              <FormItem className=''>
                <FormLabel>
                  <span className='text-sm font-medium'>Phone</span>
                </FormLabel>
                <FormControl>
                  <Input placeholder='Phone' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={formValues.control}
            name='password'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <span className='text-sm font-medium'>Password</span>
                </FormLabel>
                <FormControl>
                  <PasswordInput placeholder='Password' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </Form>
      </div>
    </div>
  );
}
