import Container from '@/components/Container';
import Header from '@/components/Header';
import { Badge } from '@/components/ui/badge';
import useTeacherById from '@/hooks/teacher/useTeacherById';
import { BarChart2, Calendar, DownloadIcon, Edit, Users } from 'lucide-react';

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Students from './Students';
import Sessions from './Sessions';
import Polls from './Polls';
import { cn } from '@/lib/utils';

type Props = {};

type Tab = 'students' | 'sessions' | 'polls';

interface TabItem {
  id: Tab;
  label: string;
  icon: React.ElementType;
}

const CourseDetail = (props: Props) => {
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const {} = useTeacherById(id as string);

  const [activeTab, setActiveTab] = useState<Tab>('students');

  const tabs: TabItem[] = [
    { id: 'students', label: 'Students', icon: Users },
    { id: 'sessions', label: 'Sessions', icon: Calendar },
    { id: 'polls', label: 'Polls', icon: BarChart2 },
  ];

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'students':
        return <Students />;
      case 'sessions':
        return <Sessions />;
      case 'polls':
        return <Polls />;
      default:
        return null;
    }
  };
  return (
    <Container>
      <Header
        title='Classes'
        placeholder='Search Class'
        setSearchTerm={setSearchTerm}
      />

      <div className='flex flex-col pb-6 px-6 gap-4  h-[calc(100vh-76px)]'>
        {/* top */}
        <div className='bg-white p-6  rounded-[5px] shadow-xl shadow-gray-200/50'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center space-x-4'>
              <div className='w-16 h-16 bg-green-200 rounded-full flex items-center justify-center text-2xl font-bold text-green-800'>
                A1
              </div>
              <div>
                <h1 className='text-2xl font-bold flex items-center'>
                  Class A1
                  <button className='ml-2'>
                    <Edit className='w-4 h-4 text-gray-400' />
                  </button>
                </h1>
                <div className='flex space-x-8 mt-2 text-sm text-gray-600'>
                  <div>
                    <span className='font-semibold'>Teacher:</span> Anouar Manaa
                    <button className='ml-1'>
                      <Edit className='w-3 h-3 text-gray-400' />
                    </button>
                  </div>
                  <div>
                    <span className='font-semibold'>Registered:</span> 10
                  </div>
                  <div>
                    <span className='font-semibold'>Age Range:</span> 7-10 Years
                  </div>
                  <div>
                    <span className='font-semibold'>Level:</span> A1
                  </div>
                  <div>
                    <span className='font-semibold'>Sessions:</span> 50/125
                  </div>
                </div>
              </div>
            </div>
            <div className='w-20 h-20'>
              <div className='relative w-full h-full'>
                <div className='absolute inset-0 flex items-center justify-center'>
                  <span className='text-2xl font-bold text-blue-600'>55%</span>
                </div>
                <svg className='w-full h-full' viewBox='0 0 36 36'>
                  <path
                    d='M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831'
                    fill='none'
                    stroke='#E5E7EB'
                    strokeWidth='2'
                  />
                  <path
                    d='M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831'
                    fill='none'
                    stroke='#3B82F6'
                    strokeWidth='2'
                    strokeDasharray='100, 100'
                    strokeDashoffset='45'
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        {/* bottom */}
        <div className='flex flex-grow gap-4 bg-background rounded-[5px]'>
          <div className='w-[100px]  bg-white rounded-[5px] shadow-xl shadow-gray-200/50'>
            <nav className='flex flex-col items-center py-4 space-y-4'>
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={cn(
                    'w-full flex flex-col items-center py-3 px-2 text-sm font-medium transition-colors',
                    activeTab === tab.id
                      ? 'bg-blue-100 text-blue-600'
                      : 'text-gray-600 hover:bg-gray-100'
                  )}
                >
                  <tab.icon className='w-6 h-6 mb-1' />
                  {tab.label}
                </button>
              ))}
            </nav>
          </div>
          <div className='flex-1 h-full bg-white rounded-[5px] shadow-xl shadow-gray-200/50 overflow-auto'>
            {renderActiveTab()}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CourseDetail;
