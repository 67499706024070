import Container from '@/components/Container';
import useAddClient from '@/hooks/clients/useAddClient';
import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Spinner from '@/components/Spinner';
import WilayaDropdown from '@/components/WilayaDropdown';
import CommuneDropdown from '@/components/CommuneDropdown';
import { Info } from 'lucide-react';

type PropsAddClient = {};

const AddClient: React.FC<PropsAddClient> = ({}) => {
  const [showInfo, setShowInfo] = useState(false);
  const [selectedWilaya, setSelectedWilaya] = useState<string>('');
  const { isSubmitting, addClientForm, onSubmit } = useAddClient();
  const navigate = useNavigate();
  const handleSubmit = async (values: Client) => {
    try {
      await onSubmit(
        values,
        (message) => {
          toast.success(message);
          navigate('/clients');
        },
        (message) => toast.error(message)
      );
    } catch (error) {
      console.error('Submission error:', error); // Log any errors
    }
  };

  return (
    <Container isSmallScreenReady>
      <div className='p-6 h-screen mt-12 md:mt-0'>
        <Form {...addClientForm}>
          <form
            onSubmit={addClientForm.handleSubmit(handleSubmit)}
            className='flex flex-col gap-4 h-full '
          >
            {/* contact info  */}
            <h1 className='font-semibold  text-[18px]'>Add Contact</h1>
            <div className='md:px-8 space-y-4 '>
              {/* name and phone */}
              <div className='flex flex-col md:flex-row gap-4 md:gap-8'>
                <FormField
                  control={addClientForm.control}
                  name='fullname'
                  rules={{ required: 'Name is required' }}
                  render={({ field }) => (
                    <FormItem className='md:w-1/2'>
                      <FormControl>
                        <Input placeholder='Name*' {...field} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className='flex gap-4 md:w-1/2'>
                  <div>
                    <div className='bg-white h-[44px] text-muted-foreground text-sm flex justify-center items-center gap-1 border border-muted-foreground rounded-md px-4'>
                      <img src='/assets/images/algeria.svg' alt='' />
                      (+213)
                    </div>
                  </div>
                  <FormField
                    control={addClientForm.control}
                    name='phone'
                    rules={{
                      required: 'Phone is required',
                      pattern: {
                        value: /^[0-9]+$/,
                        message: 'Enter a valid phone number',
                      },
                    }}
                    render={({ field }) => (
                      <FormItem className='w-[80%]'>
                        <FormControl>
                          <Input
                            type='number'
                            placeholder='07 77777777*'
                            {...field}
                          />
                        </FormControl>

                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              {/* company and email */}
              <div className='flex flex-col md:flex-row gap-4 md:gap-8'>
                <FormField
                  control={addClientForm.control}
                  name='company'
                  rules={{ required: 'Company is required' }}
                  render={({ field }) => (
                    <FormItem className='md:w-1/2'>
                      <FormControl>
                        <Input placeholder='Company*' {...field} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={addClientForm.control}
                  name='email'
                  rules={{ required: 'Email is required' }}
                  render={({ field }) => (
                    <FormItem className='md:w-1/2'>
                      <FormControl>
                        <Input type='email' placeholder='Email' {...field} />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              {/* wilaya and commune */}
              <div className='flex flex-col md:flex-row gap-4 md:gap-8'>
                <FormField
                  control={addClientForm.control}
                  name='wilaya'
                  render={({ field }) => (
                    <FormItem className='md:w-1/2'>
                      <FormControl>
                        <WilayaDropdown
                          onValueChange={(value) => {
                            field.onChange(value);
                            setSelectedWilaya(field.value);
                          }}
                          value={field.value}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={addClientForm.control}
                  name='commune'
                  render={({ field }) => (
                    <FormItem className='md:w-1/2'>
                      <FormControl>
                        <CommuneDropdown
                          wilaya={addClientForm.getValues('wilaya')}
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          placeholder='Commune'
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              {/* position */}
              <div className='flex flex-col md:flex-row md:w-1/2 md:pr-4'>
                <div className='w-full'>
                  <FormField
                    control={addClientForm.control}
                    name='positionGps'
                    rules={{
                      pattern: {
                        value: /^-?\d{1,3}\.\d+,\s?-?\d{1,3}\.\d+$/,
                        message:
                          'Enter a valid position in the format: 35.38244859633222, 1.3415783116583861',
                      },
                    }}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <div className='relative'>
                            <Input
                              type='text'
                              placeholder='34.80786765474, 65.86557475346'
                              {...field}
                              className='pr-10' // Adjust padding for icon space
                            />
                            <Info
                              className='absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-muted-foreground'
                              onClick={() => setShowInfo(!showInfo)}
                            />
                          </div>
                        </FormControl>
                        <FormMessage />
                        {showInfo && (
                          <p className='text-gray-500 text-xs mt-2'>
                            To get coordinates, open Google Maps, right-click on
                            the location, and select the first option. Copy the
                            coordinates from the info box that appears.
                          </p>
                        )}
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </div>

            <h1 className='font-semibold mt-8 text-[18px]'>
              Company information
            </h1>
            <div className='md:px-8 space-y-4'>
              {/* trade and fiscal */}
              <div className='flex flex-col md:flex-row gap-4 md:gap-8'>
                <FormField
                  control={addClientForm.control}
                  name='tradeRegisterNumber'
                  render={({ field }) => (
                    <FormItem className='md:w-1/2'>
                      <FormControl>
                        <Input
                          type='number'
                          placeholder='Trade register number'
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={addClientForm.control}
                  name='fiscalId'
                  render={({ field }) => (
                    <FormItem className='md:w-1/2'>
                      <FormControl>
                        <Input
                          type='number'
                          placeholder='Fiscal identification number'
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              {/* stats and imposition */}
              <div className='flex flex-col md:flex-row gap-4 md:gap-8'>
                <FormField
                  control={addClientForm.control}
                  name='statisticalId'
                  render={({ field }) => (
                    <FormItem className='md:w-1/2'>
                      <FormControl>
                        <Input
                          type='number'
                          placeholder='Statistical identification number'
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={addClientForm.control}
                  name='impositionArticleNumber'
                  render={({ field }) => (
                    <FormItem className='md:w-1/2'>
                      <FormControl>
                        <Input
                          type='number'
                          placeholder='Imposition article number'
                          {...field}
                        />
                      </FormControl>

                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <div className='flex flex-col md:flex-row gap-2 py-8 md:p-0 md:mt-auto mx-auto md:space-x-4'>
              <Button disabled variant='outline'>
                Generate QR code
              </Button>
              <Button disabled={isSubmitting} className='px-20' type='submit'>
                {isSubmitting ? <Spinner /> : <p>Save</p>}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </Container>
  );
};

export default AddClient;
