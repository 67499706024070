import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className='h-screen flex flex-col justify-start items-center'>
      <img className='h-[80%] w-[80%]' src='/assets/images/404.svg' alt='404' />
      <Button onClick={() => navigate('/')} size='lg' variant='outline'>
        Return Home
      </Button>
    </div>
  );
};

export default PageNotFound;
