import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store'; // Adjust the path as necessary
import axios from 'axios'; // Using axios for the API call, you can also use fetch or any other method
import api from '@/api/baseApi';
import { Button } from '@/components/ui/button';
import Spinner from '@/components/Spinner';

type Props = {
  currentStep: number;
  handleNext: () => void;
  handlePrevious: () => void;
  steps: Array<{ label: string; desc: string }>;
};

const Welcome = ({ currentStep, handleNext, handlePrevious, steps }: Props) => {
  // Access the Redux state
  const { teacherInfo, meet, contract } = useSelector(
    (state: RootState) => state.becomeATeacher
  );

  // State for loading, success, and error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean | null>(null);

  // Function to send data to the API
  const sendTeacherData = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    const teacher = {
      fullname: teacherInfo.name,
      phone: teacherInfo.phone,
      email: teacherInfo.email,
      password: teacherInfo.password,
      country: teacherInfo.country,
      englishSpoken: teacherInfo.nativeSpeaker,
      workExperience: teacherInfo.workExperience,
      certifications: teacherInfo.teachingCertificates,
      diplomas: teacherInfo.diplomas,
      meetConfirmation: meet.date,
      meetConfirmationTime: meet.time,
    };

    try {
      // Step 1: Add Teacher
      console.log({
        fullname: teacher.fullname,
        phone: teacher.phone,
        email: teacher.email,
        password: teacher.password,
        country: teacher.country,
        englishSpoken: teacher.englishSpoken,
        status: 'pending',
        role: 'teacher',
        meetConfirmation: meet.date,
        meetConfirmationStatus: 'accepted',
      });
      const response = await api.post('/users', {
        fullname: teacher.fullname,
        phone: teacher.phone,
        email: teacher.email,
        password: teacher.password,
        country: teacher.country,
        englishSpoken: teacher.englishSpoken,
        status: 'pending',
        role: 'teacher',
        meetConfirmation: meet.date,
        meetConfirmationStatus: 'accepted',
      });

      console.log('Response:', response);

      if (response.data.id) {
        console.log('Teacher added successfully');
        const userId = response.data.id;
        console.log('User ID:', userId);

        // Step 2: Add Work Experiences
        for (const work of teacher.workExperience) {
          try {
            await api.post('/experience', {
              userId,
              title: work.title,
              company: work.company,
              startDate: work.startDate,
              endDate: work.endDate,
              details: work.details,
            });
          } catch (error) {
            console.error(`Error adding work experience: ${work.title}`, error);
            setError(`Error adding work experience: ${work.title}`);
            break; // Optional: Stop the loop if one experience fails
          }
        }

        // Step 3: Add Diplomas
        for (const diploma of teacher.diplomas) {
          try {
            await api.post('/diploma', {
              userId,
              title: diploma.title,
              company: diploma.company,
              startDate: diploma.startDate,
              endDate: diploma.endDate,
              details: diploma.details,
            });
          } catch (error) {
            console.error(`Error adding diploma: ${diploma.title}`, error);
            setError(`Error adding diploma: ${diploma.title}`);
            break; // Optional: Stop the loop if one diploma fails
          }
        }

        // Step 4: Add Certifications
        for (const certification of teacher.certifications) {
          try {
            await api.post('/certification', {
              userId,
              title: certification.title,
              company: certification.company,
              startDate: certification.startDate,
              endDate: certification.endDate,
              details: certification.details,
            });
          } catch (error) {
            console.error(
              `Error adding certification: ${certification.title}`,
              error
            );
            setError(`Error adding certification: ${certification.title}`);
            break; // Optional: Stop the loop if one certification fails
          }
        }

        setSuccess(true); // All steps succeeded
      } else {
        setError('Teacher not added. Unexpected server response');
      }
    } catch (err: any) {
      setError(err.response?.data?.message || 'Error adding teacher');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    sendTeacherData();
  }, []);

  return (
    <div className='flex min-h-[50%] items-center justify-center flex-col gap-8 '>
      {/* <Button
        onClick={sendTeacherData}
        className='px-4 py-2 bg-blue-600 text-white rounded disabled:opacity-50'
      >
        Submit
      </Button> */}

      {/* Display loading state */}
      {loading && (
        <div className='flex flex-col items-center justify-center gap-8 w-[400px] py-12'>
          <Spinner />
        </div>
      )}

      {/* Display success message */}
      {success && (
        <div className='flex flex-col items-center justify-center gap-8 w-[400px] py-12'>
          <img src='/assets/images/review.svg' alt='review' />
          <h1 className='text-center text-[48px] font-semibold'>
            Informations Submitted
          </h1>
          <p className='text-center text-[18px] font-normal text-[#5D5D5D]'>
            Your information is being verified, you can move on to the next
            step, once you are approved by the administrator
          </p>
        </div>
      )}

      {/* Display error message */}
      {error && (
        <div className='flex flex-col items-center justify-center gap-8 py-12'>
          <h1 className='text-[48px] font-semibold'>Error occurred</h1>
          <p style={{ color: 'red' }}>Error: {error}</p>
        </div>
      )}

      {!success && !loading && (
        <div className='mt-8 flex w-full justify-between'>
          <button
            onClick={handlePrevious}
            disabled={currentStep === 1}
            className='px-4 py-2 bg-gray-200 text-gray-800 rounded disabled:opacity-50'
          >
            Previous
          </button>
          <button
            onClick={handleNext}
            className='px-4 py-2 bg-blue-600 text-white rounded disabled:opacity-50'
          >
            {currentStep === steps.length ? 'Finish' : 'Next'}
          </button>
        </div>
      )}
    </div>
  );
};

export default Welcome;
