import { getToken } from '@/utils/auth.util';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { token, valid } = getToken();
  const location = useLocation();

  if (valid === null) {
    return <div>Loading...</div>;
  }

  if (!valid) {
    // Redirect to login if not authenticated
    return <Navigate to='/login' state={{ from: location }} replace />;
  }

  // Render the protected route content
  return <>{children}</>;
};

export default ProtectedRoute;
