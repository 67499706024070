// import { getToken } from '@/utils/authVerification';
import { getToken } from '@/utils/auth.util';
import axios from 'axios';
import toast from 'react-hot-toast';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  async (config) => {
    const { token, valid } = getToken();

    if (token && valid) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data.message === 'session expired') {
      window.location.href = '/login'; // Change the URL to your login page
      toast.error('Session expired. Please log in again.');
    }
    return Promise.reject(error);
  }
);

export default api;
