import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from './ui/button';

type Props = {};

const Navbar = (props: Props) => {
  const navigate = useNavigate();
  return (
    <div className='  bg-[#394A99]'>
      <div className='max-w-screen-xl mx-auto py-4 flex items-center justify-between'>
        <ul className='flex gap-8 text-sm text-[#8C94A3]'>
          <li className='cursor-pointer'>Home</li>
          <li className='cursor-pointer'>About</li>
          <li className='cursor-pointer'>Contact</li>
          <li
            className='cursor-pointer'
            onClick={() => navigate('/becomeateacher')}
          >
            Become a teacher
          </li>
        </ul>

        <Button
          onClick={() => navigate('/login')}
          className='bg-[#677EEA] text-white rounded-[10px]'
        >
          Login
        </Button>
      </div>
    </div>
  );
};

export default Navbar;
