import { deleteClient } from '@/api/client';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter,
  DialogClose,
} from '@/components/ui/dialog';
import { Trash } from 'lucide-react';
import toast from 'react-hot-toast';

interface DeleteProps {
  clientId: string | undefined;
  onDelete: () => void;
}

const Delete: React.FC<DeleteProps> = ({ clientId, onDelete }) => {
  const handleDeleteCompany = async () => {
    try {
      if (!clientId) return toast.success('doesnot exist');
      const res = await deleteClient(clientId);
      if (res?.status === 200) console.log('Client deleted', res);
      onDelete();
      toast.success('Client deleted successfully');
    } catch (error) {
      console.error('Error deleting Client', error);
    }
  };
  return (
    <Dialog>
      <DialogTrigger className='h-7 w-7 border flex justify-center items-center border-[#FF0000]/90 rounded-lg hover:bg-[#FF0000]/10 transition ease-in-out duration-200'>
        <img
          className='h-[17px] w-[17px]'
          src='/assets/icons/trash.svg'
          alt='delete'
        />
      </DialogTrigger>
      <DialogContent className='md:w-[25%] w-[90%] px-8 bg-white'>
        <DialogHeader>
          <DialogTitle className='font-medium text-base md:text-lg'>
            {' '}
            Are you sure you want to delete this client?
          </DialogTitle>

          <DialogFooter className='flex flex-row w-full   justify-center space-x-4'>
            <DialogClose className='mt-4' asChild>
              <Button
                className='rounded-[5px] w-1/2 border-[#FF0000] bg-[#FF0000]/10 text-[#FF0000] hover:bg-[#FF0000]/20'
                variant='outline'
                type='button'
              >
                No
              </Button>
            </DialogClose>
            <Button
              className='rounded-[5px] mt-4 w-1/2 border-[#FF0000] bg-[#FF0000] text-white hover:bg-[#FF0000]/90'
              type='button'
              onClick={handleDeleteCompany}
            >
              Yes
            </Button>
          </DialogFooter>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default Delete;
