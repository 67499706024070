import { getUserRole } from './auth.util';

interface RoleBasedComponentProps {
  allowedRoles: string[];
  children: React.ReactNode | null | undefined;
}

export const RoleBasedComponent: React.FC<RoleBasedComponentProps> = ({
  allowedRoles,
  children,
}) => {
  const role = getUserRole();
  console.log('role', role);

  if (role && allowedRoles.includes(role) && children) {
    return <>{children}</>; // Return a fragment wrapping the children
  }
  return null;
};
