import {
  Select,
  SelectContent,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import useWilayas from '@/hooks/useWilayas';
import Spinner from './Spinner';
import { Button } from './ui/button';

interface WilayaDropdownProps {
  value: string;
  onValueChange: (value: string) => void;
  isFilter?: boolean;
}

const WilayaDropdown = ({
  value,
  onValueChange,
  isFilter = false,
}: WilayaDropdownProps) => {
  const { wilayas, loading } = useWilayas();
  return (
    <Select onValueChange={onValueChange} defaultValue={value}>
      <SelectTrigger
        className={` ${
          isFilter
            ? 'border-none bg-transparent'
            : 'h-[44px] border border-[#C9C9C9] bg-white'
        }  font-normal flex justify-between w-full rounded-[5px]  px-3 py-1 text-sm  transition-colors placeholder:text-muted-foreground/50   disabled:cursor-not-allowed disabled:opacity-50 `}
      >
        {value ? (
          <span
            className={` ${isFilter ? 'text-[#979797] font-medium mr-2' : ''} `}
          >
            {value}
          </span>
        ) : (
          <span
            className={` ${
              isFilter ? 'text-[#979797] font-medium mr-2' : 'text-[#C9C9C9]'
            } `}
          >
            Wilaya
          </span>
        )}
      </SelectTrigger>
      <SelectContent>
        {loading ? (
          <SelectItem value='loading'>
            <Spinner />
          </SelectItem>
        ) : (
          <>
            {wilayas.length === 0 ? (
              <SelectItem value='No wilayas found'>No wilayas found</SelectItem>
            ) : (
              <>
                {isFilter && <SelectItem value='all'>All Wilayas</SelectItem>}
                {wilayas.map((item, index) => (
                  <SelectItem key={item.id} value={item.name}>
                    {index + 1} - {item.name}
                  </SelectItem>
                ))}
              </>
            )}
          </>
        )}
      </SelectContent>
    </Select>
  );
};

export default WilayaDropdown;
