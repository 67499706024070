import { ColumnDef, flexRender } from '@tanstack/react-table';
import { Table as ReactTableType } from '@tanstack/react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

import Spinner from '@/components/Spinner';

interface DataTableProps<TData, TValue> {
  table: ReactTableType<TData>;
  columns: ColumnDef<TData, TValue>[];
  loading: boolean;
  error: string | null;
}

export function DataTable<TData, TValue>({
  columns,
  loading,
  error,
  table,
}: DataTableProps<Client, TValue>) {
  // Loading or error states
  if (loading)
    return (
      <div className='h-[67vh] flex items-center justify-center'>
        <Spinner />
      </div>
    );
  if (error)
    return (
      <div className='h-[67vh] flex items-center justify-center'>{error}</div>
    );

  return (
    <div className='flex flex-col min-h-[77vh]'>
      <div className=' rounded-[10px] border-[#ECEEF6] bg-white'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead className='w-1/5' key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell className='w-1/5 font-normal' key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className='h-24 text-center'
                >
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
