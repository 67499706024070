import React from 'react';
import Sidebar from './Sidebar';
import { useLocation } from 'react-router-dom';

interface ContainerProps {
  children: any;
  isSmallScreenReady?: boolean;
}

const Container: React.FC<ContainerProps> = ({
  children,
  isSmallScreenReady = false, // default to false if not provided
}) => {
  const location = useLocation();
  const currentRoute = location.pathname;

  return (
    <div className='flex'>
      <Sidebar />
      <div className='md:ml-[269px] w-full'>
        {isSmallScreenReady ? (
          // Render the children directly if the screen can be small
          <div className='min-h-[75vh] bg-background'>{children}</div>
        ) : (
          <>
            {/* Render children only on medium and larger screens */}
            <div className='hidden md:block min-h-[75vh] h-[89.5vh]  bg-background'>
              {children}
            </div>
            {/* Show a message on small screens */}
            <div className=' md:hidden min-h-[75vh] bg-background flex items-center justify-center text-center px-12'>
              Please use a larger screen to view this content.
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Container;
