import { getTeachers } from '@/api/teacher';
import { useState, useEffect } from 'react';

interface MetaData {
  total: number;
  lastPage: number;
  currentPage: number;
  perPage: number;
  prev: number | null;
  next: number | null;
}

interface ClientResponse {
  data: Teacher[];
  meta: MetaData;
}

export const useTeachers = (
  page: number,
  perPage: number,
  searchTerm: string
) => {
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const [meta, setMeta] = useState<MetaData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchTeachers = async () => {
    setLoading(true);
    try {
      const response: ClientResponse = await getTeachers({
        page,
        perPage,
        searchTerm,
      });
      setTeachers(response.data);
      setMeta(response.meta);
    } catch (err) {
      setError('Failed to fetch teachers');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTeachers();
  }, [page, perPage, searchTerm]);

  const refresh = () => {
    fetchTeachers();
  };

  return { teachers, refresh, meta, loading, error };
};
