import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { MenuIcon } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type PropsMobileMenu = {};

const MobileMenu: React.FC<PropsMobileMenu> = () => {
  const [currentRoute, setCurrentRoute] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location]);

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  return (
    <Sheet key='left'>
      <SheetTrigger className='fixed top-5 left-4 p-2  rounded-full bg-[#FBFBFB]'>
        <MenuIcon />
      </SheetTrigger>
      <SheetContent side='left' className='bg-white h-[100vh]'>
        <SheetHeader>
          <SheetTitle>
            <div className=' ml-3  -mt-8 h-20'>
              <img src='/assets/icons/Hillal.svg' alt='logo' />
            </div>
          </SheetTitle>
          <SheetDescription className='flex flex-col h-[75vh] justify-between '>
            <div className='flex  pt-8 flex-col gap-2'>
              <div
                onClick={() => handleNavigate('/clients')}
                className={`flex gap-5 px-4 py-3 items-center text-sm cursor-pointer hover:bg-muted/50 transition duration-250 ease-in-out rounded-[12px] ${
                  currentRoute === '/clients' || currentRoute === '/'
                    ? 'font-medium text-textDark bg-muted '
                    : 'text-[#848382]'
                }`}
              >
                <span className='flex justify-start items-start'>Client</span>
              </div>
              <div
                onClick={() => handleNavigate('/collector')}
                className={`flex gap-5 px-4 py-3 items-center text-sm cursor-pointer hover:bg-muted/50 transition duration-250 ease-in-out rounded-[12px] ${
                  currentRoute === '/collector'
                    ? 'font-medium text-textDark bg-muted '
                    : 'text-[#848382]'
                }`}
              >
                <span className='flex justify-start items-start'>
                  Collector
                </span>
              </div>
              <div
                onClick={() => handleNavigate('/collectionorders')}
                className={`flex gap-5 px-4 py-3 items-center text-sm cursor-pointer hover:bg-muted/50 transition duration-250 ease-in-out rounded-[12px] ${
                  currentRoute === '/collectionorders'
                    ? 'font-medium text-textDark bg-muted '
                    : 'text-[#848382]'
                }`}
              >
                <span className='flex justify-start items-start'>
                  Collection Orders
                </span>
              </div>
              <div
                onClick={() => handleNavigate('/employee')}
                className={`flex gap-5 px-4 py-3 items-center text-sm cursor-pointer hover:bg-muted/50 transition duration-250 ease-in-out rounded-[12px] ${
                  currentRoute === '/employee'
                    ? 'font-medium text-textDark bg-muted '
                    : 'text-[#848382]'
                }`}
              >
                <span className='flex justify-start items-start'>Employee</span>
              </div>
            </div>
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};

export default MobileMenu;
