import { getClients } from '@/api/client';
import { useState, useEffect } from 'react';

interface MetaData {
  total: number;
  lastPage: number;
  currentPage: number;
  perPage: number;
  prev: number | null;
  next: number | null;
}

interface ClientResponse {
  data: Client[];
  meta: MetaData;
}

export const useClients = (
  page: number,
  perPage: number,
  searchTerm: string,
  selectedWilaya: string
) => {
  const [clients, setClients] = useState<Client[]>([]);
  const [meta, setMeta] = useState<MetaData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchClients = async () => {
    console.log('fetching', page, perPage, searchTerm);
    setLoading(true);
    try {
      const response: ClientResponse = await getClients({
        page,
        perPage,
        searchTerm,
        selectedWilaya,
      });
      setClients(response.data);
      setMeta(response.meta);
    } catch (err) {
      setError('Failed to fetch clients');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchClients();
  }, [page, perPage, searchTerm, selectedWilaya]);

  const refresh = () => {
    fetchClients();
  };

  return { clients, refresh, meta, loading, error };
};
