import api from './baseApi';

export const getWilayas = async () => {
  try {
    const response = await api.get('/wilayas/wilayas');
    return response.data;
  } catch (error) {
    console.error('Error fetching wilayas:', error);
    return [];
  }
};

export const getCommunes = async (wilaya: string) => {
  try {
    const response = await api.get(`/wilayas/wilayas/${wilaya}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching communes:', error);
    return [];
  }
};
