import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface WorkExperience {
  title: string;
  company: string;
  startDate: string;
  endDate: string;
  details: string;
}

interface TeacherInfo {
  name: string;
  email: string;
  phone: string;
  password: string;
  country: string;
  nativeSpeaker: boolean;
  workExperience: WorkExperience[];
  teachingCertificates: WorkExperience[];
  diplomas: WorkExperience[];
  cvFile: File | null;
}

interface Meet {
  date: string;
  time: string;
}

interface Contract {
  checked: boolean;
}

interface BecomeATeacherState {
  teacherInfo: TeacherInfo;
  meet: Meet;
  contract: Contract;
}

const initialState: BecomeATeacherState = {
  teacherInfo: {
    name: '',
    email: '',
    phone: '',
    password: '',
    country: '',
    nativeSpeaker: false,
    workExperience: [],
    teachingCertificates: [],
    diplomas: [],
    cvFile: null,
  },
  meet: {
    date: '',
    time: '',
  },
  contract: {
    checked: false,
  },
};

const becomeATeacherSlice = createSlice({
  name: 'becomeATeacher',
  initialState,
  reducers: {
    updateTeacherInfo: (state, action: PayloadAction<Partial<TeacherInfo>>) => {
      state.teacherInfo = { ...state.teacherInfo, ...action.payload };
    },
    updateMeet: (state, action: PayloadAction<Partial<Meet>>) => {
      state.meet = { ...state.meet, ...action.payload };
    },
    updateContract: (state, action: PayloadAction<Partial<Contract>>) => {
      state.contract = { ...state.contract, ...action.payload };
    },
    deleteWorkExperience: (state, action: PayloadAction<number>) => {
      state.teacherInfo.workExperience.splice(action.payload, 1);
    },
    deleteTeachingCertificate: (state, action: PayloadAction<number>) => {
      state.teacherInfo.teachingCertificates.splice(action.payload, 1);
    },
    deleteDiploma: (state, action: PayloadAction<number>) => {
      state.teacherInfo.diplomas.splice(action.payload, 1);
    },
    deleteCvFile: (state) => {
      state.teacherInfo.cvFile = null;
    },
  },
});

export const {
  updateTeacherInfo,
  updateMeet,
  updateContract,
  deleteWorkExperience,
  deleteTeachingCertificate,
  deleteCvFile,
  deleteDiploma,
} = becomeATeacherSlice.actions;
export default becomeATeacherSlice.reducer;
