import { ColumnDef } from '@tanstack/react-table';

export const columns: ColumnDef<Consultant>[] = [
  {
    accessorKey: 'fullname',
    header: 'Client',
  },

  {
    accessorKey: 'balance',
    header: () => <div className='text-left'>Balance</div>,
    cell: ({ row }) => {
      const amount = row.getValue('balance')
        ? parseFloat(row.getValue('balance'))
        : 0;
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'DZD',
      }).format(amount);

      return <div className='text-left '>{formatted}</div>;
    },
  },
  {
    accessorKey: 'lastPayment',
    header: () => <div className='text-left'>Last Payment</div>,
    cell: ({ row }) => {
      const amount = row.getValue('balance')
        ? parseFloat(row.getValue('balance'))
        : 0;
      const formatted = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'DZD',
      }).format(amount);

      return <div className='text-left '>{formatted}</div>;
    },
  },
  {
    accessorKey: 'lastPaymentDate',
    header: () => <div className='text-left'>Last Payment Date</div>,
    cell: ({ row }) => {
      const date = row.getValue('lastPaymentDate')
        ? new Date(row.getValue('lastPaymentDate'))
        : new Date();
      const formatted = new Intl.DateTimeFormat('en-US', {
        dateStyle: 'medium',
      }).format(date);

      return <div className='text-left '>{formatted}</div>;
    },
  },
  {
    accessorKey: 'wilaya',
    header: 'Wilaya',
    // header: ({ column }) => {
    //   return (
    //     <Button
    //       variant='ghost'
    //       className='p-0'
    //       onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
    //     >
    //       wilaya
    //       <ArrowUpDown className='ml-2 h-4 w-4' />
    //     </Button>
    //   );
    // },
  },
];
