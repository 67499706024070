import Container from '@/components/Container';
import Header from '@/components/Header';
import { Badge } from '@/components/ui/badge';
import useTeacherById from '@/hooks/teacher/useTeacherById';
import { DownloadIcon } from 'lucide-react';

import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Ban from './Ban';
import MessageParent from './MessageParent';

type Props = {};

const StudentDetail = (props: Props) => {
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const {} = useTeacherById(id as string);
  return (
    <Container>
      <Header
        title='Student'
        placeholder='Student Teacher'
        setSearchTerm={setSearchTerm}
      />
      <div className='flex flex-col px-6 pb-8 gap-4'>
        {/* crumbs */}
        <p className='text-[#8C94A3] font-semibold'>
          Student &gt;
          <span className='text-[#677EEA]'>{id}</span>
        </p>

        <div className='flex gap-6'>
          {/* left section */}
          <div className='w-1/4 flex flex-col gap-6'>
            {/* profile */}
            <div className='bg-white rounded-[5px] flex flex-col items-center gap-6 p-8 shadow-xl shadow-gray-200/50'>
              <img
                className='rounded-full w-[112px] p-2 h-[112px] mx-auto bg-slate-200'
                src='/assets/images/logo.svg'
                alt='logo'
              />
              <div className='flex flex-col items-center'>
                <h1 className='text-[20px] font-semibold'>Lotfi</h1>
                <p className='text-[#8C94A3] text-sm font-normal'>joined</p>
              </div>
              <Badge variant={'active'}>Active</Badge>
            </div>

            {/* stat */}
            <div className='bg-white rounded-[5px] flex flex-col items-center gap-6 p-8 shadow-xl shadow-gray-200/50'>
              <div className='flex gap-10'>
                <div className='flex flex-col gap-2 items-center'>
                  <p className='font-normal text-center '>Age range</p>
                  <p className='font-semibold '>7 - 10 Years</p>
                </div>
                <div className='flex flex-col gap-2 items-center'>
                  <p className='font-normal text-center '>Level</p>
                  <p className='font-semibold '>A1</p>
                </div>
              </div>
            </div>

            {/* download cv */}
            <div className='bg-white rounded-[5px] flex items-center justify-between gap-6 p-6 shadow-xl shadow-gray-200/50'>
              <div className='flex gap-4'>
                <img src='/assets/icons/file.svg' alt='file' />
                <p>Download CV</p>
              </div>
              <DownloadIcon />
            </div>

            {/* information */}
            <div className='bg-white rounded-[5px] flex flex-col  gap-6 p-8 shadow-xl shadow-gray-200/50'>
              <div className='flex flex-col gap-2 items-start'>
                <p className='font-normal '>Name</p>
                <p className='font-semibold '>lotfi</p>
              </div>
              <div className='flex flex-col gap-2 items-start'>
                <p>Email</p>
                <p className='font-semibold '>lotfi@mail.com</p>
              </div>

              <div className='flex flex-col gap-2 items-start'>
                <p>Password</p>
                <p className='font-semibold '> ********</p>
              </div>
            </div>
          </div>

          {/* right section */}
          <div className='w-3/4 flex flex-col gap-6'>
            {/* action */}
            <div className='bg-white rounded-[5px] flex items-center justify-between gap-6 p-8 shadow-xl shadow-gray-200/50'>
              <div className='flex flex-col gap-2 items-start'>
                <p className='font-semibold text-xl'>Actions</p>
                <p className='font-normal text-sm text-[#8C94A3] '>
                  Report the student or send a message to the parent
                </p>
              </div>
              <div className='flex  gap-2 items-center'>
                <MessageParent />
                <Ban />
              </div>
            </div>
            {/* clases */}
            <div className='bg-white rounded-[5px] flex items-center justify-between gap-6 p-8 shadow-xl shadow-gray-200/50'>
              <div className='flex flex-col gap-2 items-start'>
                <p className='font-semibold text-xl'>Studnet's classes</p>
                <p className='font-normal text-sm text-[#8C94A3] '>
                  Here you find the classes that the student belong to
                </p>
              </div>
              <div className='flex flex-col gap-2 items-center'>
                Number of classes : 10
              </div>
              <div className='flex flex-col gap-2 items-center'>
                Show classes
              </div>
            </div>

            {/* teacher inforamtion */}
            <div className='bg-white rounded-[5px] flex flex-col divide-y-2 divide-[#EEEEEE] gap-6 p-8 shadow-xl shadow-gray-200/50'>
              <div className='flex items-center'>
                <div className='w-1/2 flex flex-col gap-2 items-start'>
                  <h1 className='font-semibold text-xl'>Consultant Report</h1>
                  <p className='font-normal text-sm text-[#8C94A3] '>
                    here you find the files that you downloaded during the
                    lessons
                  </p>
                </div>

                <div className='w-1/2 flex  gap-2 items-center justify-between'>
                  <div className='flex gap-2'>
                    <p className=''>Date</p>
                    <p className='font-semibold'>2002/02/02</p>
                  </div>
                  <div className='flex gap-2'>
                    <p className=''>Prepared by</p>
                    <p className='font-semibold'>Rick and morty</p>
                  </div>
                </div>
              </div>
              <div className='py-4 h-[500px] space-y-8   overflow-scroll'>
                <div className='space-y-8'>
                  <h2 className='font-bold text-[24px]'>Executive Summary</h2>
                  <p className='px-4 text-[16px] font-normal text-[#8C94A3]'>
                    This needs-analysis report aims to identify the specific
                    needs of students interested in learning English. The
                    analysis encompasses various aspects such as proficiency
                    levels, learning goals, preferred learning styles, and
                    potential challenges. The information gathered will inform
                    the development of a tailored English language learning
                    program to meet the diverse needs of the target student
                    population.
                  </p>
                </div>
                <div className='space-y-8'>
                  <h2 className='font-bold text-[24px]'>Introduction</h2>
                  <p className='px-4 text-[16px] font-normal text-[#8C94A3]'>
                    English language proficiency is a key skill in today's
                    globalized world. The purpose of this needs-analysis is to
                    gain insights into the unique requirements of students
                    seeking to learn English. Understanding their goals,
                    preferences, and challenges will enable us to design an
                    effective and engaging learning experience.
                  </p>
                </div>
                <div className='space-y-8'>
                  <h2 className='font-bold text-[24px]'>Recommendations</h2>
                  <p className='px-4 text-[16px] font-normal  text-[#8C94A3]'>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default StudentDetail;
