import React, { useState } from 'react';
import { Input } from './ui/input';
import { Avatar, AvatarFallback } from './ui/avatar';
import { logout } from '@/redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/redux/store';
import { Button } from './ui/button';
import { getUser } from '@/utils/auth.util';

type PropsHeader = {
  title?: string;
  setSearchTerm: (searchTerm: string) => void;
  placeholder?: string;
};

const Header: React.FC<PropsHeader> = ({
  setSearchTerm,
  title,
  placeholder,
}) => {
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    setSearchTerm(value); // Pass the search term to the parent component
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  return (
    <header className=' flex justify-between items-center p-4 '>
      <div className='flex gap-20 items-center'>
        <h1 className='font-semibold text-[24px] mr-2'>{title}</h1>
        <div className='mt-14   md:mt-0 pt-2 flex items-center'>
          <div className=' w-auto relative z-20 left-2  top-4 transform -translate-y-1/2'>
            <img
              className='w-8 h-8'
              src='/assets/icons/search.svg'
              alt='search'
            />
          </div>

          <Input
            value={inputValue}
            onChange={handleInputChange}
            placeholder={placeholder ? placeholder : 'Rechercher'}
            className='pl-12 absolute w-[92%]  md:w-[380px] bg-[#FBFBFB] border-none rounded-lg shadow-sm'
          />
        </div>
      </div>

      <div className='-mt-10 md:mt-0 flex items-center gap-2'>
        <Avatar className='mr-2 '>
          <AvatarFallback>
            {getUser().fullname.charAt(0).toUpperCase()}
          </AvatarFallback>
        </Avatar>
        <div className='flex justify-center items-center w-[44px] h-[44px] '>
          <img src='/assets/icons/Bell.svg' alt='notifications' />
        </div>
        <div className='flex justify-center items-center w-[44px] h-[44px] '>
          <img src='/assets/icons/setting.svg' alt='setting' />
        </div>
        <div
          onClick={handleLogout}
          className='flex justify-center items-center w-[44px] h-[44px] rounded-xl  cursor-pointer hover:bg-destructive/20 transition duration-200'
        >
          <img src='/assets/icons/logout.svg' alt='logout' />
        </div>
      </div>
    </header>
  );
};

export default Header;
