import { getWilayas } from '@/api/wilaya';
import { useState, useEffect } from 'react';

type Wilaya = {
  id: number;
  name: string;
};

const useWilayas = () => {
  const [wilayas, setWilayas] = useState<Wilaya[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWilayas = async () => {
      try {
        const response = await getWilayas();
        setWilayas(response);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching wilayas:', error);
        setLoading(false);
      }
    };

    fetchWilayas();
  }, []);

  return { wilayas, loading };
};

export default useWilayas;
