import { getConsultants } from '@/api/consultant';
import { useState, useEffect } from 'react';

interface MetaData {
  total: number;
  lastPage: number;
  currentPage: number;
  perPage: number;
  prev: number | null;
  next: number | null;
}

interface ClientResponse {
  data: Consultant[];
  meta: MetaData;
}

export const useConsultants = (
  page: number,
  perPage: number,
  searchTerm: string
) => {
  const [consultants, setConsultants] = useState<Consultant[]>([]);
  const [meta, setMeta] = useState<MetaData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchConsultants = async () => {
    setLoading(true);
    try {
      const response: ClientResponse = await getConsultants({
        page,
        perPage,
        searchTerm,
      });
      setConsultants(response.data);
      setMeta(response.meta);
    } catch (err) {
      setError('Failed to fetch consultants');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConsultants();
  }, [page, perPage, searchTerm]);

  const refresh = () => {
    fetchConsultants();
  };

  return { consultants, refresh, meta, loading, error };
};
