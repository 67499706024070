import { getStudents } from '@/api/student';
import { useState, useEffect } from 'react';

interface MetaData {
  total: number;
  lastPage: number;
  currentPage: number;
  perPage: number;
  prev: number | null;
  next: number | null;
}

interface ClientResponse {
  data: Student[];
  meta: MetaData;
}

export const useStudents = (
  page: number,
  perPage: number,
  searchTerm: string
) => {
  const [students, setStudents] = useState<Student[]>([]);
  const [meta, setMeta] = useState<MetaData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchStudents = async () => {
    setLoading(true);
    try {
      const response: ClientResponse = await getStudents({
        page,
        perPage,
        searchTerm,
      });
      setStudents(response.data);
      setMeta(response.meta);
    } catch (err) {
      setError('Failed to fetch students');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudents();
  }, [page, perPage, searchTerm]);

  const refresh = () => {
    fetchStudents();
  };

  return { students, refresh, meta, loading, error };
};
