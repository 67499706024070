import { TrashIcon } from 'lucide-react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteWorkExperience } from '@/redux/slices/becomeATeacherSlice';
import { formatIsoToMonthYear } from '@/utils/formatDate';

interface WorkExperience {
  title: string;
  company: string;
  startDate: string;
  endDate: string;
  details: string;
}

type Props = {
  workExperience: WorkExperience;
  index: number; // Add index prop to identify the experience
};

const WorkExperienceCard: React.FC<Props> = ({ workExperience, index }) => {
  const dispatch = useDispatch();

  const handleDeleteWorkExperience = () => {
    dispatch(deleteWorkExperience(index));
  };

  return (
    <div className='pr-4 mt-4'>
      <div className='flex gap-4 items-center justify-between'>
        <h2 className='text-[20px] truncate max-w-full'>
          {workExperience.title}
        </h2>

        <TrashIcon
          color='#FF0000'
          size={20}
          className='cursor-pointer'
          onClick={handleDeleteWorkExperience}
        />
      </div>

      <div className='flex gap-2 text-[14px] items-center'>
        <p>{workExperience.company},</p>
        <p className='text-[10px]'>
          {formatIsoToMonthYear(workExperience.startDate)},
          {formatIsoToMonthYear(workExperience.endDate)}
        </p>
      </div>
      <p className='text-[14px] text-[#707070] mt-4'>
        {workExperience.details}
      </p>
    </div>
  );
};

export default WorkExperienceCard;
