import api from './baseApi';

export const addTeacher = async (teacher: Teacher) => {
  try {
    const response = await api.post('/teacher', teacher);
    return response;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }
};

export const getTeachers = async ({
  page = 1,
  perPage = 10,
  searchTerm,
}: {
  page?: number;
  perPage?: number;
  searchTerm?: string;
}) => {
  try {
    let query = `?page=${page}&perPage=${perPage}`;
    if (searchTerm) {
      query += `&fullname=${searchTerm}`;
    }

    const response = await api.get(`/users/list/client${query}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching teachers', error);
    return [];
  }
};

export const deleteTeacher = async (teacherId: string) => {
  try {
    const response = await api.delete(`/teacher/${teacherId}`);
    return response;
  } catch (error) {
    console.error('Error deleting teacher', error);
  }
};

export const getTeacher = async (teacherId: string) => {
  try {
    const response = await api.get(`/teacher/${teacherId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching teacher', error);
  }
};
